import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';

import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogThreeComponent } from './components/pages/blog-three/blog-three.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { PortfolioTwoComponent } from './components/pages/portfolio-two/portfolio-two.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { WebDesignComponent } from './components/pages/web-design/web-design.component';
import { SoftwereDevelopmentComponent } from './components/pages/softwere-development/softwere-development.component';
import { GraphicDesiginingComponent } from './components/pages/graphic-desigining/graphic-desigining.component';

import { AppDevelopmentComponent } from './components/pages/app-development/app-development.component';
import { WebDevelopmentComponent } from './components/pages/pricing/web-development/web-development.component';
import { SocialMediaMarketingComponent } from './components/pages/pricing/social-media-marketing/social-media-marketing.component';
import { PpcManagmentComponent } from './components/pages/pricing/ppc-managment/ppc-managment.component';
import { ContentWriterComponent } from './components/pages/pricing/content-writer/content-writer.component';
import { TermsPolicyComponent } from './components/pages/terms-policy/terms-policy.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { ShippingComponent } from './components/pages/shipping/shipping.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';

import { ConstructionComponent } from './components/pages/industries/construction/construction.component';
import { ElearningComponent } from './components/pages/industries/elearning/elearning.component';
import { FinanceComponent } from './components/pages/industries/finance/finance.component';
import { HealthcareComponent } from './components/pages/industries/healthcare/healthcare.component';
import { MeetingeventsComponent } from './components/pages/industries/meetingevents/meetingevents.component';
import { NonprofitComponent } from './components/pages/industries/nonprofit/nonprofit.component';
import { PaymentsComponent } from './components/pages/industries/payments/payments.component';
import { RealestateComponent } from './components/pages/industries/realestate/realestate.component';
import { RetailComponent } from './components/pages/industries/retail/retail.component';
import { SupplychainComponent } from './components/pages/industries/supplychain/supplychain.component';
import { TeleconmmunicationComponent } from './components/pages/industries/teleconmmunication/teleconmmunication.component';
import { TransportationComponent } from './components/pages/industries/transportation/transportation.component';
import { HospitalityComponent } from './components/pages/industries/hospitality/hospitality.component';
import { AnimationgraphicComponent } from './components/pages/solutions/animationgraphic/animationgraphic.component';
import { CloudComponent } from './components/pages/solutions/cloud/cloud.component';
import { CustomcrmComponent } from './components/pages/solutions/customcrm/customcrm.component';
import { DigitalmarketingComponent } from './components/pages/solutions/digitalmarketing/digitalmarketing.component';
import { ErpComponent } from './components/pages/solutions/erp/erp.component';
import { FacilitiesmanagementComponent } from './components/pages/solutions/facilitiesmanagement/facilitiesmanagement.component';
import { ManageddbComponent } from './components/pages/solutions/manageddb/manageddb.component';
import { MiddlewareComponent } from './components/pages/solutions/middleware/middleware.component';
import { MobiletabletComponent } from './components/pages/solutions/mobiletablet/mobiletablet.component';
import { SalesmanagementComponent } from './components/pages/solutions/salesmanagement/salesmanagement.component';
import { TicketingComponent } from './components/pages/solutions/ticketing/ticketing.component';
import { WebdevelopementComponent } from './components/pages/solutions/webdevelopement/webdevelopement.component';
import { ApiDevelopmentComponent } from './components/pages/services/api-development/api-development.component';
import { ApplicationDevelopementComponent } from './components/pages/services/application-developement/application-developement.component';
import { ApplicationMaintenanceComponent } from './components/pages/services/application-maintenance/application-maintenance.component';
import { BussinessProcessComponent } from './components/pages/services/bussiness-process/bussiness-process.component';
import { CustomSoftwareServicesComponent } from './components/pages/services/custom-software-services/custom-software-services.component';
import { DevopsComponent } from './components/pages/services/devops/devops.component';
import { ImplementationDeploymentComponent } from './components/pages/services/implementation-deployment/implementation-deployment.component';
import { MigrationUpgradesComponent } from './components/pages/services/migration-upgrades/migration-upgrades.component';
import { QaTestigComponent } from './components/pages/services/qa-testig/qa-testig.component';
import { SoftwareSecurityComponent } from './components/pages/services/software-security/software-security.component';
import { SystemsIntegrationComponent } from './components/pages/services/systems-integration/systems-integration.component';
import { OurworkComponent } from './components/pages/ourwork/ourwork.component';
import { SitemapComponentComponent } from './sitemap-component/sitemap-component.component';



const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./components/backend/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: '', component: HomeOneComponent },

  { path: 'about', component: AboutComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'service-details', component: ServiceDetailsComponent },
  { path: 'portfolio-two', component: PortfolioComponent },
  { path: 'portfolio', component: PortfolioTwoComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'team', component: TeamComponent },
  { path: 'team-two', component: TeamTwoComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'shipping', component: ShippingComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'terms-policy', component: TermsPolicyComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-two', component: BlogTwoComponent },
  { path: 'blog-three', component: BlogThreeComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'web-design', component: WebDesignComponent },
  { path: 'softwere-development', component: SoftwereDevelopmentComponent },
  { path: 'graphic-desigining', component: GraphicDesiginingComponent },
  { path: 'app-development', component: AppDevelopmentComponent },
  { path: 'web-development', component: WebDevelopmentComponent },
  { path: 'elementor-17945', component: SocialMediaMarketingComponent },
  { path: 'ppc-2', component: PpcManagmentComponent },
  { path: 'checkout', component: CheckoutComponent },


  { path: 'content-writing', component: ContentWriterComponent },
  { path: 'construction', component: ConstructionComponent },
  { path: 'elearning', component: ElearningComponent },
  { path: 'finance', component: FinanceComponent },
  { path: 'healthcare', component: HealthcareComponent },
  { path: 'meetings', component: MeetingeventsComponent },
  { path: 'nonprofit', component: NonprofitComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'realestate', component: RealestateComponent },
  { path: 'retail', component: RetailComponent },
  { path: 'supplychain', component: SupplychainComponent },
  { path: 'telecom', component: TeleconmmunicationComponent },
  { path: 'transport', component: TransportationComponent },
  { path: 'hospitality', component: HospitalityComponent },



  { path: 'anime', component: AnimationgraphicComponent },
  { path: 'cloud', component: CloudComponent },
  { path: 'customcrm', component: CustomcrmComponent },
  { path: 'digital-marketing', component: DigitalmarketingComponent },
  { path: 'erp', component: ErpComponent },
  { path: 'facility-management', component: FacilitiesmanagementComponent },
  { path: 'managed-db', component: ManageddbComponent },
  { path: 'middleware', component: MiddlewareComponent },
  { path: 'mobile-tablet', component: MobiletabletComponent },
  { path: 'sales-management', component: SalesmanagementComponent },
  { path: 'ticketing', component: TicketingComponent },
  { path: 'web-developement', component: WebdevelopementComponent },

  { path: 'api-developement', component: ApiDevelopmentComponent },
  { path: 'application-developement', component: ApplicationDevelopementComponent },
  { path: 'application-maintainence', component: ApplicationMaintenanceComponent },
  { path: 'business-process', component: BussinessProcessComponent },
  { path: 'custom-softwares', component: CustomSoftwareServicesComponent },
  { path: 'devops', component: DevopsComponent },
  { path: 'impl-deploy', component: ImplementationDeploymentComponent },
  { path: 'migration-upgrades', component: MigrationUpgradesComponent },
  { path: 'qa-testing', component: QaTestigComponent },
  { path: 'software-security', component: SoftwareSecurityComponent },
  { path: 'system-integration', component: SystemsIntegrationComponent },
  { path: 'our-work', component: OurworkComponent },

  // {path: 'sitemap.xml', component: SitemapComponentComponent},



  { path: '**', component: ErrorComponent },
  // Here add new pages component

  { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
