import { Component } from '@angular/core';

@Component({
  selector: 'app-implementation-deployment',
  templateUrl: './implementation-deployment.component.html',
  styleUrls: ['./implementation-deployment.component.scss']
})
export class ImplementationDeploymentComponent {

}
