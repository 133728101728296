import { Component } from '@angular/core';

@Component({
  selector: 'app-nonprofit',
  templateUrl: './nonprofit.component.html',
  styleUrls: ['./nonprofit.component.scss']
})
export class NonprofitComponent {

}
