<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>CONSTRUCTION SOFTWARE
                                DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Construction Automation Tools</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/cERP.webp" width="100%"/>
                                <p>Construction ERP</p>
                             </div>
                        </div>
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/af.webp" width="100%"/>
                                <p>Accounting & Finances</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/ps.webp" width="100%"/>
                                <p>Payroll Software</p>
                             </div>
                        </div>
                        
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/industries/bm.webp" width="100%"/>
                               <p>Bid Management</p>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/aas.webp" width="100%"/>
                                <p>Asset Accounting Software</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/jca.webp" width="100%"/>
                                <p>Job Cost Accounting</p>
                             </div>
                        </div>
                       
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <!-- <img src="assets/img/banner2.webp" alt="theory image"> -->
                <img src="/assets/img/industry-pages/construction.webp" />

            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Elevate Construction Management : Your Vision, Our Software</span>
                        <h2>Construction Software Development Services</h2>
                    </div>
                   <p>
                    With a profound understanding of the construction landscape, we specialize in crafting tailored software solutions that address the unique challenges faced by builders, contractors, and developers. Our expertise includes Construction ERP, Payroll Software, Bid Management, and more.
<br/><br/>
At Hari Web Infotech, we believe in harnessing technology to empower construction professionals. Our solutions optimize resource allocation, track project progress, and facilitate real-time collaboration among stakeholders. We focus on scalability, ensuring our software can accommodate projects of varying complexities and sizes.
<br/><br/>
What sets us apart is our commitment to delivering user-friendly interfaces, robust data security, and seamless integration with existing construction processes. Our team of skilled developers ensures that our software aligns seamlessly with your specific requirements, enhancing productivity and reducing project risks.
<br/><br/>
Elevate your construction endeavors with our Construction Software Development services. Partner with Hari Web Infotech Pvt Ltd to embark on a digital transformation journey that redefines construction project management. Contact us today to explore how our solutions can shape the future of your construction projects.
                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    
                    <h2>Why Choose Us?</h2>
                    
                    

                  
                    
                    
                    
                    
                    
                    
                    


                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Industry Insight:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We have a deep understanding of the construction sector, allowing us to develop solutions that align with your business goals.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Customization:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe that each construction business is unique. Our software solutions are highly customizable to fit your specific workflows and requirements.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. User-Friendly Interfaces: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our user-centric designs make software navigation intuitive and user-friendly, ensuring rapid adoption across your team.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Scalability:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Whether you're starting small or aiming big, our software solutions are designed to scale with your business growth.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            5. Support and Training:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We provide comprehensive support and training to ensure that you harness the full potential of our software solutions.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            6. Innovation:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We stay updated with the latest technological advancements, integrating the best practices and innovations into our solutions.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
 
<app-slider></app-slider>








