<app-navbar-one></app-navbar-one>
<style >
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

    .Latest{
        @media (max-width: 767px) {
            
        }
    }
</style>
<div class="main-banner" id="home-bnner">
     <div class="container">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
                <div class="banner-text">
                    <h1>OUR <br/>WORKS</h1>
                    <div class="theme-button">
                        <a routerLink="/contact"  class="default-btn p-2">Get Demo Now</a>
                    </div>
                </div>
                <app-logoslider></app-logoslider>
            </div>
            <div class="col-sm-1"></div>
        </div>
      
     
    </div>
</div>

<section class="portfolio-section portfolio-style-two pt-50 pb-70">
    <div class="container">
     
        <section class="about-section "> 
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 port-folio">
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="about-image-port">
                            
                                    <a href="https://neetanaturals.com/"><img src="assets/img/neeta.png"  alt="about image"></a>
                                </div>
                            </div>
                            <div class="col-sm-7 content-port">
                                
                                    <h2>Beauty Product Website</h2>
                                
                                <p>With our ecommerce website access the convenience at your fingertips. Shop anytime, anywhere for an extensive range of beauty products. Enjoy doorstep delivery, secure payments, and access to customer reviews for informed choices.</p>
                                <p>With our ecommerce website access the convenience at your fingertips. Shop anytime, anywhere for an extensive range of beauty products. Enjoy doorstep delivery, secure payments, and access to customer reviews for informed choices.</p>
                                <ul class="text-start">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <li>Logo Design</li>
                                        <li>Graphic Design</li>
                                        <li>Product Listing</li>
                                        </div>
                                        <div class="col-sm-4">
                                            <li>Complete E-Commerce</li>
                                            <li>Payment Gateway</li>
                                            <li>Live Tracking System</li>

                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-12 port-folio">
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="about-image-port">
                            
                                    <a href="https://themultilingualgroup.com/"  class="default-btn me-4 p-2"> <img src="assets/img/The-Multi-lingual-groups.png" alt="about image"></a>
                                </div>
                            </div>
                            <div class="col-sm-7 content-port">
                                
                                
                                    <h2>The Multi-Lingual Group Website</h2>
                                
                                <p>Enhanced visibility and reach. 24/7 accessibility. Efficient customer engagement. Global market access. Streamlined transactions. Data-driven insights. Brand credibility. Competitive advantage. A powerful platform for growth and success.</p>
                              
                                <ul class="text-start">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <li>Website Design</li>
                                            <li >Logo Design</li>
                                            <li >Graphic Design</li>
                                        </div>
                                        <div class="col-sm-4">
                                            <li >Reviews</li>
                                    <li >Service Listing</li>
                                    <li >Social Media Listing</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 port-folio">
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="about-image-port">
                            
                                    <a href="https://unity-connect.com/" class="default-btn me-4 p-2"><img src="assets/img/Business-Process-Outsourcing-Services-_-Unity-Communications.png" alt="about image"></a>
                                </div>
                            </div>
                            <div class="col-sm-7 content-port">
                                
                            
                              
                                <h2>Business Process Outsourcing Website
                                </h2>
                                
                                <p>Enhanced visibility and reach. 24/7 accessibility. Efficient customer engagement. Global market access. Streamlined transactions. Data-driven insights. Brand credibility. Competitive advantage. A powerful platform for growth and success.</p>
                              
                                <ul class="text-start">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <li>Website Design</li>
                                    <li >Logo Design</li>
                                    <li >Graphic Design</li>
                                        </div>
                                        <div class="col-sm-4">
                                            <li >SEO (On Page)</li>
                                            <li >Digital Marketing</li>
                                            <li >Social Media</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
       
    </div>
</section>
<section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
           
            <h2>Latest Works For Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/NET-Xperts-_-Computer-Network-Support-_-Pittsburgh-PA.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>NET Xperts</h3>
                            <a href="https://thenetxperts.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Schneider-Electric-India-_-Global-Specialist-In-Energy-Management.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Schneider Electric India</h3>
                            <a href="https://www.se.com/in/en/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/headache.healthcaresuccess.com_ (1).png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Rgmasalon</h3>
                            <a href="https://headache.healthcaresuccess.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.pharmeasy.in_.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>pharmeasy</h3>
                            <a href="https://pharmeasy.in/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.medpick.in_.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Medpick</h3>
                            <a href="https://medpick.in/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.mdsave.com_.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Mdsave</h3>
                            <a href="https://www.mdsave.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.lifecare.com_.au_.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Lifecare</h3>
                            <a href="https://www.lifecare.com.au/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.globalcoachinginstitute.orgusa.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Globalcoachinginstitute</h3>
                            <a href="https://globalcoachinginstitute.org/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/www.bblanguages.com_.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>BB languages</h3>
                            <a href="https://www.bblanguages.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Home-MainStreet-Bank.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Home mainstreet</h3>
                            <a href="https://www.mstreetbank.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Home-_-Paramount.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Paramount</h3>
                            <a href="https://www.paramount.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Global-Issues-_-social-political-economic-and-environmental-issues-that-affect-us-all-—-Global-Issues.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Global-Issues</h3>
                            <a href="https://www.globalissues.org/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Global-Alliance-of-SMEs.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Global-Alliance</h3>
                            <a href="http://www.globalsmes.org/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/FAB-BAG_-Monthly-Makeup-Beauty-Subscription-for-Women.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>FAB-BAG Monthly Makeup</h3>
                            <a href="https://www.fabbag.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/doola-_-Get-Your-LLC-EIN-Bank-Accounts-_-International-Founder-Startup-Guide-to-Corporate-Setup-Taxes-More.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Doola</h3>
                            <a href="https://www.doola.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Concierge-Level-Business-Process-Outsourcing-Services-_-Helpware.png" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Concierge</h3>
                            <a href="https://www.helpware.com/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-8">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/Buy-Cosmetics-Beauty-Fregrance-Products-Online-at-Best-Price-_-NewU.png" alt="portfolio image">

                        <div class="portfolio-text">
                           <h3>Buy Cosmetics Beauty</h3>
                            <a href="https://www.newu.in/" class="portfolio-btn">View Demo <i class="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
           
               
           
        </div>
    </div>
</section>
<app-slider></app-slider>

