<app-navbar-one></app-navbar-one>
<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Software Development</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Software Development</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-details">
   <div class="container mt-5">
    <div class="row software mt-5">
        <div class="col">
           <h2>Welcome to Hari Web Infotech Pvt Ltd - Your Trusted Partner in Software Development</h2>
           <p>At Hari Web Infotech Pvt Ltd, we are a leading software development company that specializes in delivering innovative and cutting-edge solutions to businesses of all sizes. With our expertise in a wide range of software products, we offer comprehensive solutions tailored to meet your unique needs.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
        <div class="col mt-3"><img src="assets/img/crm.jpeg" alt=""></div>
    </div>
    <div class="row software mt-5">
        <div class="col mt-3"><img src="assets/img/softwere-devp-1.webp" alt=""></div>
        <div class="col">
           <h2>CRM (Customer Relationship Management)</h2>
           <p>Maximize customer interactions and boost business growth with our cutting-edge CRM software. Effortlessly manage leads, track sales, and nurture customer relationships all in one platform. Elevate your team's productivity and deliver exceptional customer experiences. Stay ahead of the competition with our advanced CRM solution. Try it today and unlock the full potential of your business.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
        </div>
    </div>
    <div class="row software mt-5">
        <div class="col">
           <h2>HR Management System</h2>
           <p>Optimize your HR operations with our intuitive HR management system. Experience seamless employee onboarding, streamlined payroll management, and comprehensive HR administration. Say goodbye to manual processes and embrace efficiency with our user-friendly software. From talent acquisition to performance tracking, our solution caters to all your HR needs. Empower your HR team to focus on strategic initiatives and enhance overall workforce productivity. Take the first step towards simplified HR management today.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
        <div class="col mt-3"><img src="assets/img/hr-managment.webp" alt=""></div>
    </div>
    <div class="row software mt-5">
        <div class="col mt-3"><img src="assets/img/chat.jpeg" alt=""></div>
        <div class="col">
           <h2>Internal Chat System</h2>
           <p>Empower your organization with our secure and efficient internal chat system. Strengthen communication and collaboration between teams with real-time messaging and file sharing capabilities. Experience seamless integration and streamline workflows, driving productivity to new heights. Safeguard sensitive information with our robust security measures, ensuring confidentiality at every step. Enhance teamwork, foster innovation, and achieve organizational success with our feature-rich internal chat system. Embrace seamless communication and transform the way your teams collaborate today.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
        
    </div>
    <div class="row software mt-5">
        <div class="col">
           <h2>Invoicing Software</h2>
           <p>Take control of your invoicing and billing processes with our feature-rich invoicing software. Easily generate professional invoices, tailored to your brand, and send them to clients with just a few clicks. Our user-friendly interface ensures a seamless experience, helping you get paid faster and improve cash flow. Simplify financial tracking and streamline your billing operations to focus on what matters most – growing your business. Try our invoicing software today and unlock efficiency in your financial management.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
        <div class="col mt-3"><img src="assets/img/invoicing-softwere.webp" alt=""></div>
    </div>
    <div class="row software mt-5">
        <div class="col mt-3"><img src="assets/img/inventory.jpeg" alt=""></div>
        <div class="col">
           <h2>Inventory Management Software</h2>
           <p>Seize control of your inventory with our robust inventory management software. Effortlessly monitor stock levels, efficiently manage orders, and streamline your supply chain for maximum efficiency. With real-time data insights, make informed decisions to avoid stockouts and overstocking. Our user-friendly interface simplifies the entire inventory process, saving you time and resources. Drive business growth and customer satisfaction by ensuring products are always available when needed. Take charge of your inventory management with our powerful software today.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
      
    </div>
    <div class="row software mt-5">
        <div class="col">
           <h2>Employee Management Software</h2>
           <p>Elevate your workforce management with our all-inclusive employee management software. Monitor and evaluate employee performance effortlessly, ensuring productivity and efficiency. Keep track of attendance with ease, eliminating manual record-keeping. Streamline HR tasks, from onboarding to leave management, to enhance operational efficiency. Our comprehensive solution empowers you with data-driven insights for better decision-making. Maximize your team's potential and organizational success by embracing our powerful employee management software. Unlock the true potential of your workforce today.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
        <div class="col mt-3"><img src="assets/img/employee.jpeg" alt=""></div>
    </div>
    <div class="row software mt-5">
        <div class="col mt-3"><img src="assets/img/lead-managmnet.jpeg" alt=""></div>
        <div class="col">
           <h2>Lead Management Software</h2>
           <p>Unleash your sales potential with our cutting-edge lead management software. Seamlessly capture leads from various channels and efficiently track their progress through the sales pipeline. Utilize automated processes and nurture leads to conversion, maximizing your customer base. Gain valuable insights into lead behavior and preferences to tailor your approach. Let our lead management software empower your sales team to achieve unprecedented success and drive business growth. Capture, track, and convert leads into valuable customers today.</p>

<div class="theme-button">
    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
    <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Demo</a>
</div>
          
        </div>
       
    </div>
   </div>
</section>
<div class="service-section mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 softwere-devp-2"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <p>Best</p>
                    <h2 class="mb-0">Why Choose Hari Web Infotech Pvt Ltd?</h2>
                    <!-- <p class="mt-0"> We provide a comprehensive and Mobile Game App Development Company In Delhi, India and solutions from ground zero to end. Hariweb InfoTech is one of the pioneers in Mobile Game App Development Company In Delhi, India that provides highly competitive solutions from designing to development to marketing of your game. Our designers, artists, coders, and marketers have expertise on various types of games for various platforms such as PC, Web, Mobile, and Consoles. We can assist you in various kinds of games such as 2D, 3D, single player, multiple players, RPGs with AR and VR integration, and many more. Our approach for Mobile Game App Development Services Delhi includes a number of latest tools, technologies, engines, and frameworks available in the market such as Unity3D, Unreal, HTML5, JavaScript, Cocos2d-x, etc. Share your ideas with us and we will turn it into a fun and exciting mobile game.</p> -->
                  
                        <p>Customized Solutions: We understand that every business is unique. Our expert team crafts tailor-made solutions to align with your specific requirements.</p>
                        <p>User-Centric Design: Our software products are designed with a focus on user experience, ensuring ease of use and seamless navigation.</p>
                        <p>Cutting-Edge Technology: We stay ahead of the curve by incorporating the latest technologies and industry best practices into our products.</p>
                        <p>Security & Confidentiality: Your data's security is our top priority. We implement robust security measures to safeguard your sensitive information.</p>
                        <p>Timely Delivery: We value your time. Our efficient development process ensures on-time delivery without compromising on quality</p>
                        <p>Expert Support: Our dedicated support team is available round-the-clock to address any queries or issues you may encounter.</p>
                        <p> Competitive Pricing: We offer cost-effective solutions that provide excellent value for your investment.Experience the Power of Innovative Software Solutions with Hari Web Infotech Pvt Ltd!</p>
                  
                   
                    <div class="theme-button">
                        <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2">Call Expert</a>
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn me-4 p-2">Schedule a Meeting</a>
                        
                     </div>
                     
                </div>
                
                
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img src="assets/img/softwere-devp-3.webp" alt="service details">
                    </div>

                 <!------------------
                       <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    ----------------------->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/web-design">WEBSITE DESIGNING <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/app-development">APP DEVELOPMENT <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/softwere-development">SOFTWARE DEVELOPMENT <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/graphic-desigining">GRAPHICS DESIGNING<i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/digital-marketing">DIGITAL MARKETING<i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 softwere-devp-4">
              <img src="assets/img/softwere-devp-4.webp" alt="">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>
                   
                    <form id="contactForm"  (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email" [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject" [(ngModel)]="formData.subject" required [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message" [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                               
                               
                                
                                    <button type="submit" class="default-btn contact-btn me-4 p-2">Send Message</button>
                                   
                                     <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn contact-btn me-4 p-2">Schedule a Meeting</button>
                              
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>
