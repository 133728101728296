<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9">
                <div class="header-left-text">
                    <ul>
                        <li><a href="tel:+91 9990335957">
                            <img src="assets/img/indiaflag.svg" height="30px" width="22px" alt="Flag of India">&nbsp;
                            +91 999-033-5957</a></li>
                        <li><a href="tel:+1 442-259-2040">
                            <img src="assets/img/usflag.svg" height="30px" width="22px"  alt="Flag of India">&nbsp;
                            
                            +1 442-259-2040</a></li>
                        <li><a href="mailto:support@hariwebinfotech.com"><i class="flaticon-envelope"></i> support@hariwebinfotech.com</a></li>
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-3">

                <div class="header-right text-end">
                    
                    <div class="header-social">
                        <ul>
                            <li><a href="https://www.facebook.com/hariwebinfotechpvtltd" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="https://twitter.com/hari_web" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="https://in.linkedin.com/company/hari-web-infotech" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/hwi_097/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/hwiwhite.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <!-- <img src="assets/img/Hariwebinfotech3.webp" alt="logo"> -->
                    <img src="assets/img/hwi.webp" alt="logo">

                </a>

                <div  class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" routerLink="/" class="nav-link" routerLinkActive="active">Home</a>
                        </li>
                       
                        <li class="nav-item">
                            <a [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" [ngClass]="activeCheck?'active':''"  class="nav-link dropdown-toggle">Industries<i class="bi bi-chevron-down"></i></a>
                            <ul class="dropdown-menu Services p0">
                                
                                <div class="row  d-flex justify-content-center">
                                    <div class="col ">
                                        <div class="row pe-0 ps-0">
                                            <div class="col-sm-6 pe-0 ps-0 bbr">
                                                
                                               
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  routerLink="/construction">Construction</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/elearning">E-Learning & Education
                                                    </a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/finance">Finance</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  routerLink="/healthcare">Healthcare</a>
                                                </div>
                                                <div class="col"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/hospitality">Hospitality & Travel</a></div>
                                                <div class="col"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/nonprofit">Non Profit</a></div>
                                                
                                            </div>
                                            <div class="col-sm-6 pe-0 ps-0">
                                               
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/payments">Payments</a></div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/retail">Retail</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/realestate">Real Estate</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/supplychain">Supply Chain</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/telecom">Telecommunications</a>
                                                </div>
                                              
                                              
                                            </div>
                                           
                                        </div>
                                       
                                       </div>
                                      
                                </div>
                        </ul>
                        </li>
                       
                        <li class="nav-item">
                            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle">Solutions<i class="bi bi-chevron-down"></i></a>
                            <ul class="dropdown-menu Services p0">
                                
                                <div class="row  d-flex justify-content-center">
                                    <div class="col ">
                                        <div class="row pe-0 ps-0">
                                            <div class="col-sm-6 pe-0 ps-0 bbr">
                                                
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/anime">Animation & Graphic Design</a></div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/cloud">Cloud</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/customcrm">Custom CRM</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/managed-db">Managed Databases</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/digital-marketing">Digital Marketing</a>
                                                </div>
                                                <div class="col"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/erp">ERP</a></div>
                                                
                                            </div>
                                            <div class="col-sm-6 pe-0 ps-0">
                                                
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/facility-management">Facilities Management</a></div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/middleware">Middleware</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/mobile-tablet">Mobile & Tablet</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/sales-management">Sales Management</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/ticketing">Ticketing</a>
                                                </div>
                                              
                                              
                                            </div>
                                         
                                        </div>
                                   
                                       </div>
                                   
                                </div>
                        </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services<i class="bi bi-chevron-down"></i></a>
                            <ul class="dropdown-menu Services p0">
                                
                                <div class="row  d-flex justify-content-center">
                                    <div class="col ">
                                        <div class="row pe-0 ps-0">
                                            <div class="col-sm-6 pe-0 ps-0 bbr">
                                                
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/api-developement">API Development</a></div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/web-developement">Web Development</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/application-maintainence">Application Maintenance</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/business-process">Business Process Outsourcing</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/custom-softwares">Custom Software Services</a>
                                                </div>
                                                <div class="col"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/devops">DevOps</a></div>
                                                
                                            </div>
                                            <div class="col-sm-6 pe-0 ps-0">
                                                
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/impl-deploy">Implementation & Deployment</a></div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/migration-upgrades">Migrations & Upgrades</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/qa-testing">Quality Assurance (QA Testing)</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/software-security">Software Security</a>
                                                </div>
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/system-integration">Systems Integration</a>
                                                </div>
                                              
                                            </div>
                                          
                                        </div>
                                     
                                       </div>
                                     
                                </div>
                        </ul>

                        </li>

                        
                       
                        

                        <li class="nav-item">
                            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle">Portfolio<i class="bi bi-chevron-down"></i></a>
                            <ul class="dropdown-menu Services Services2 p0">
                                
                                <div class="row  d-flex justify-content-center">
                                   
                                        
                                            <div class="col-sm-12 pe-0 ps-0">
                                                
                                                <!-- <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/portfolio">Our Clients</a></div> -->
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/our-work">Our Works</a>
                                                </div>
                                               
                                            </div>
                                        
                                       
                                       
                                      
                                </div>
                        </ul>
                        </li>

                        <li class="nav-item">
                            <a  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle">Company<i class="bi bi-chevron-down"></i></a>
                            <ul class="dropdown-menu Services Services2 p0">
                                
                                <div class="row  d-flex justify-content-center">
                                   
                                        
                                            <div class="col-sm-12 pe-0 ps-0">
                                                
                                                <div class="col"> <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/about">About Us</a></div>
                                                <!-- <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/service-details">Careers</a>
                                                </div>
                                              
                                               
                                                <div class="col">
                                                    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/service-details">Locations</a>
                                                </div>
                                                
                                                <div class="col"><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/service-details">Reviews</a></div> -->
                                                
                                            </div>
                                        
                                       
                                       
                                      
                                </div>
                        </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                       
                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>
<!--start modal -->
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header ">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Schedule a Meeting</h1>
          <button type="button" class="btn-close " id="btn-spiner" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body contact-section">
     
              <div class="contact-form p-4 pb-0 pt-0">
                  <form id="contactForm"  (ngSubmit)="onSubmit()">
                      <div class="row">
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="name" class="name">Name</label>
                                  <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="name" name="name" id="name" class="form-control" placeholder="">
                              </div>
                          </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                            <div class="form-group">
                                <label for="number" >Mobile Number</label>
                                <input [(ngModel)]="formData.phone" required [ngModelOptions]="{standalone: true}" type="number" name="number" id="number" class="form-control" placeholder="">
                            </div>
                        </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="email">Email</label>
                                  <input [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}" type="email" name="email" id="email" class="form-control" placeholder="">
                              </div>
                          </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                            <div class="form-group">
                                <label for="service">Service</label>
                                <select [(ngModel)]="formData.serviceType" required [ngModelOptions]="{standalone: true}" class="form-select form-control" aria-label="Default select example">
                                    <option >Select</option>
                                    <option value="Website Designing">Website Designing</option>
                                    <option value="App Development">App Development</option>
                                    <option value="Software Development">Software Development</option>
                                    <option value="Graphic Designing">Graphic Designing</option>
                                    <option value="Digital Marketing">Digital Marketing</option>
                                  </select>
                            </div>
                          </div>
                          
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="message-text" class="col-form-label">Message</label>
                              <textarea [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}" class="form-control" id="message-text"></textarea>
                              </div>
                          </div>
                      </div>
                      <div class="row p-3">
                        <button type="submit" class="default-btn contact-btn me-2 pt-2 pb-2">Send</button>
                      </div>
                        
                  </form>
  
              </div>
        </div>
        
      </div>
    </div>
  </div> -->
  <!-- end modal -->