<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 softwere-devp-4">
              <img src="assets/img/softwere-devp-4.webp" alt="">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>
                   
                    <form id="contactForm"  (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email" [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject" [(ngModel)]="formData.subject" required [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message" [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                               
                               
                                
                                    <button type="submit" class="default-btn contact-btn me-4 p-2">Send Message</button>
                                   
                                     <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn contact-btn me-4 p-2">Schedule a Meeting</button>
                              
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>