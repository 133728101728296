import { Component } from '@angular/core';

@Component({
  selector: 'app-software-security',
  templateUrl: './software-security.component.html',
  styleUrls: ['./software-security.component.scss']
})
export class SoftwareSecurityComponent {

}
