import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-animationgraphic',
  templateUrl: './animationgraphic.component.html',
  styleUrls: ['./animationgraphic.component.scss']
})
export class AnimationgraphicComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Best Graphic Designing Company in Noida , Delhi - Graphic Design Services");

    this.meta.updateTag({ name: 'description', content: "Hari Web Infotech is the best graphic design agency in Noida, India, that provides graphic design services, including motion graphic, infographic, logo, web design that bring your brand's vision to life." });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }

}
