<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>NONPROFIT MANAGEMENT SOFTWARE DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Financial & Analytics Non-Profit Programming Solutions</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/mec.webp" width="100%"/>
                                <p>MASS EMAIL CAMPAIGNS</p>
                             </div>
                        </div>
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/sm.webp" width="100%"/>
                                <p>SOCIAL MEDIA</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/ma.webp" width="100%"/>
                                <p>MARKETING AUTOMATION</p>
                             </div>
                        </div>
                        
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/industries/iadw.webp" width="100%"/>
                               <p>INTERACTIVE AND ACTION DRIVEN WEBSITE</p>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/auc.webp" width="100%"/>
                                <p>AUTOMATED CALLING</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/memp.webp" width="100%"/>
                                <p>MEMBER PORTAL</p>
                             </div>
                        </div>
                       
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/industry-pages/Financial & Analytics Non-Profit Programming Solutions.webp" class="w-100" alt="theory image">
            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Empowering Nonprofits Through Innovative Software Solutions</span>
                        <h2>Elevate Your Impact with Nonprofit Management Software </h2>
                    </div>
                   <p>
                    Unlock the power of technology for your nonprofit organization with Hari Web Infotech Pvt Ltd's Nonprofit Management Software Development Services. We are dedicated to empowering nonprofits with cutting-edge software solutions that enhance efficiency, streamline operations, and amplify your impact on the community.
                 <br/><br/>   
                    Our team of skilled developers and nonprofit experts collaborates seamlessly to create bespoke software solutions that cater to the unique needs of nonprofit organizations. From donor management and fundraising platforms to volunteer coordination and program tracking, our solutions are designed to simplify complex processes and drive meaningful change.
                    <br/><br/>
                    With a deep understanding of the challenges faced by nonprofits, we deliver highly customizable software that aligns perfectly with your organization's goals and values. Our user-centric designs ensure easy adoption and engagement, both for your team and your supporters.
                   
                    <br/><br/>
                    Empower your nonprofit to do more with Hari Web Infotech Pvt Ltd. Contact us today to explore how our Nonprofit Management Software Development Services can help you streamline operations, engage stakeholders, and make a lasting difference in the communities you serve.


                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








