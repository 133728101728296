<app-navbar-one></app-navbar-one>

<div class="main-banner" id="home-bnner">
    <div class="container">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
                <div class="banner-text">
                    <h1>WEB GRAPHIC DESIGN & ANIMATION SERVICES</h1>
                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn p-2"
                            >Get Developers Now</a
                        >
                    </div>
                </div>
                <app-logoslider></app-logoslider>
            </div>
            <div class="col-sm-1"></div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-sm-6 contentOne">
            <h3>Web Animation & Graphic Design Services</h3>
            <p>
                Our talented Web Animators and Graphic Designers can design a
                brand new website from scratch or make customized modifications
                to an existing website using the latest technologies in 2D & 3D
                animation to strengthen company brands and turn more viewers
                into loyal customers.
            </p>
            <ul>
                <li><b>Video Animation Design</b></li>
                <p>
                    We offer intuitive video animation designs to create
                    engaging and interactive assets for websites, mobile apps,
                    web apps, social media profiles, and email marketing
                    campaigns.
                </p>
                <li><b>Logos & Branding</b></li>
                <p>
                    We help companies fulfill their branding vision by
                    developing a brand from scratch or improving their existing
                    brand using our masterful tools and skills.
                </p>
                <li><b>Interactive Web Pages</b></li>
                <p>
                    We design interactive web pages using custom modules,
                    features, and software development tools to create an
                    engaging environment throughout the navigation process.
                </p>
            </ul>
        </div>
        <div class="col-sm-6">
            <img src="assets/img/solutions/anime.png" alt="" />
        </div>
    </div>
</div>

<section class="about-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span
                            >Immersive Visual Experiences by Hari Web Infotech
                            Pvt Ltd for a Digital Impact</span
                        >
                        <h2>
                            Elevate Your Online Presence with Captivating Web
                            Graphic Design & Animation
                        </h2>
                    </div>

                    <p>
                        Unleash the creative potential of your brand with Hari
                        Web Infotech Pvt Ltd's <a href="/anime"><b>Web Graphic Design & Animation
                            Services in Noida,Delhi</b></a>. Our expert designers and animators collaborate
                        to bring your digital presence to life, creating
                        visually stunning experiences that captivate and engage
                        your audience.
                        <br /><br />
                        With a keen eye for aesthetics and a passion for
                        innovation, we craft custom web designs that reflect
                        your brand identity while ensuring seamless user
                        experiences. From sleek and modern to whimsical and
                        vibrant, our designs are tailored to resonate with your
                        target audience.
                        <br /><br />
                        Our animation experts transform ideas into dynamic
                        visuals that convey complex messages with simplicity and
                        impact. From animated logos and banners to interactive
                        elements, our animations add an extra layer of charm and
                        interactivity to your website.
                        <br /><br />
                        Whether you're launching a new website or seeking to
                        refresh your current one, our services cater to
                        businesses of all sizes and industries. Our designs are
                        responsive and optimized for various devices, ensuring
                        your message reaches your audience, no matter where they
                        are.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>

                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseOne"
                                    class="collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        To become the most preferred brand
                                        offering transformative digital
                                        marketing, HR and IT solutions globally
                                        where client’s imaginations are turned
                                        into reality.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseTwo"
                                    class="collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        We believe in consistency and continual
                                        improvement as it determines our
                                        customer satisfaction that gives us
                                        sustainable competitive advantage and
                                        customers' confidence in the highly
                                        competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3. Timeline:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseThree"
                                    class="collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        Our commitment to the timeline for each
                                        project stems from the belief that every
                                        business has a strategy for its growth
                                        and our strict adherence to delivery
                                        timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseFour"
                                    class="collapse"
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        We are aligned on our intentions and
                                        actions realizing the importance of the
                                        data and information we handle for our
                                        client. We are committed to maintain
                                        absolute confidentiality of our client’s
                                        business secrets not only during the
                                        period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>
