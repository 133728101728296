<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9">
                <div class="header-left-text">
                    <ul>
                        <li><a href="tel:+0123456789"><i class="flaticon-call"></i> +91 9990335957</a></li>
                        <li><a href="mailto:hello@ezlu.com"><i class="flaticon-envelope"></i> support@hariwebinfotech.com</a></li>
    
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-3">
                <div class="header-right text-end">
                    <div class="header-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area navbar-style-two">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/Hariwebinfotech3.webp" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/Hariwebinfotech3.webp" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="#" routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>

                            <!----------------- <i class="flaticon-right"></i>
                                <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link text-white" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>
                            </ul>
                                ------------------------->
                        </li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a href="#" routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services<i class="flaticon-right"></i></a>
                            <ul class="dropdown-menu Services p-5">
                                
                                <div class="row  d-flex justify-content-center">
                                    <div class="col ">
                                        <div class="row pe-0 ps-0">
                                            <div class="col pe-0 ps-0">
                                                <h5><i class="bi bi-arrow-right me-2"></i>WEBSITE DESIGNING</h5>
                                                <div class="col"> <a href="#" routerLink="/service-details">Custom Web Design</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Custom Website Designing</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Dynamic Website Designing</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Website Redisgn</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">.Net Website Development</a>
                                                </div>
                                                <div class="col"><a href="#" routerLink="/service-details">Static Website Design</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">UI/UX Web Design</a>
                                                </div>
                                                <div class="col"><a href="#" routerLink="/service-details">Responsive Web Design</a></div>
                                            </div>
                                            <div class="col pe-0 ps-0">
                                                <h5><i class="bi bi-arrow-right me-2"></i>APP DEVELOPMENT</h5>
                                                <div class="col"> <a href="#" routerLink="/service-details">Android App Development</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">IOS App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Native App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Hybrid App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">AR App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">VR App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Business App Development</a>
                                                </div>
                                            </div>
                                            <div class="col pe-0 ps-0">
                                                <h5><i class="bi bi-arrow-right me-2"></i>SOFTWARE DEVELOPMENT</h5>
                                                <div class="col text-start"> <a href="#" routerLink="/service-details">CRM Softwere Development</a></div>
                                                <div class="col ">
                                                    <a href="#" routerLink="/service-details">Ecommerce Softwere Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Mobile Game Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Custom Web Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Hybrid App Development</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Navtive App Development</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col pe-0 ps-0">
                                               <h5><i class="bi bi-arrow-right me-2"></i>GRAPHICS DESIGNING</h5>
                                               <div class="col"> <a href="#" routerLink="/service-details">Business Card Design</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Flyer Design</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Logo Design</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Brochure Design</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Banner Design</a>
                                                </div>
                                                
                                            </div>
                                            <div class="col pe-0 ps-0">
                                                <h5><i class="bi bi-arrow-right me-2"></i>OTHER SERVICES</h5>
                                                <div class="col"> <a href="#" routerLink="/service-details">App Explainer Video</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">White Bord Animation</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">3d Animation</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">2d Animation</a>
                                                </div>
                                                
                                            </div>
                                            <div class="col pe-0 ps-0">
                                                <h5><i class="bi bi-arrow-right me-2"></i>DIGITAL MARKETING</h5>
                                                <div class="col"> <a href="#" routerLink="/service-details">Mobile Marketing / SMS marketing</a></div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Video Marketing</a>
                                                </div>
                                                <div class="col">
                                                    <a href="#" routerLink="/service-details">Email Marketing</a>
                                                </div>
                                            </div>
        
                                        </div>
                                       </div>
                                       <div class="col-3">
                                        <img src="assets/img/hire-developer.jpg" alt="" width="">
                                       </div>
                                </div>
                        </ul>

                         <!-------------------------------<i class="flaticon-right"></i>
                               <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                            </ul>----------------------------------------->
                        </li>

                        <li class="nav-item">
                            <a href="#" routerLink="/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio </a>

                            <!-------------------<i class="flaticon-right"></i>
                                <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio One</a></li>

                                <li class="nav-item"><a routerLink="/portfolio-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio Two</a></li>
                            </ul>
                                ---------------------------->
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pricing <i class="flaticon-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Development</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Social Media Marketng</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PPC Managment</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Content Writer</a></li>

                               <!-------------
                                 <li class="nav-item"><a routerLink="/team-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team Two</a></li>

                                <li class="nav-item"><a routerLink="/privacy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy policy</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Hover for action <i class="flaticon-right"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/" class="nav-link">Level 2</a></li>

                                        <li class="nav-item"><a routerLink="/" class="nav-link">Level 2</a></li>

                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Level 2 <i class="flaticon-right"></i></a>

                                            <ul class="dropdown-menu">
                                                <li class="nav-item"><a routerLink="/" class="nav-link">3rd Level</a></li>

                                                <li class="nav-item"><a routerLink="/" class="nav-link">3rd Level</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                ------------------------->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link " routerLink="/blog-two">Company</a>
<!--------------------
     <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog One</a></li>

                                <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Three</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
    --------------------------->
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="other-option">
                        <div class="search-bar">
                            <i class="flaticon-search search-icon"></i>
                            <div class="search-form">
                                <form>
                                    <input type="text" placeholder="Search" class="search-input">
                                    <button type="submit">
                                        <i class="flaticon-search search-btn"></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div class="sidebar-toggle">
                            <button type="button" class="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                <i class="flaticon-list"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>