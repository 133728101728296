import { HttpClient } from '@angular/common/http';
import { Component, Renderer2 } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-softwere-development',
  templateUrl: './softwere-development.component.html',
  styleUrls: ['./softwere-development.component.scss']
})
export class SoftwereDevelopmentComponent {
  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'
  }

  constructor(private http: HttpClient, private renderer: Renderer2){

  }
  successNotification() {
    Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
  }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  } 
  
  resetForm(){
  this.formData=
  {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'
  }
}
onSubmit(){
  const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
  if(this.formData.email===''){
    this.formData.email="N/A";
  }
   if(this.formData.phone===''){
this.formData.phone='N/A';
  }
   if(this.formData.subject===''){
    this.formData.subject='N/A';
      }
      if(this.formData.serviceType===''){
        this.formData.serviceType='N/A';
          }
  this.http.post(apiUrl,this.formData).subscribe(
    (response)=> {
      // alert("Message Sent Successfully! Our Team Will Contact You Shortly");
    
   this.successNotification();
  
      console.log('emailsend');
    
      this.resetForm();
    },(error)=>{
      console.log('error',error);
    }
  )
}

}
