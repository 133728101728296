<app-navbar-one></app-navbar-one>
<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Graphic Designing</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Graphic Designing</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-details">
    <div class="container mt-5">
        <div class="row Card-Design mt-5">
            <div class="col">
                <h2>Logo Design</h2>
                <p>
                    Attract attention and establish brand recognition with our
                    captivating logo design service. Our talented designers will
                    create a custom logo that encapsulates your brand's essence
                    and resonates with your target audience. We take pride in
                    crafting unique and meaningful logos that leave a remarkable
                    impact, setting your business apart from the competition.
                    Let our creative expertise bring your brand to life and make
                    a lasting impression on your customers.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/logo-design.jpeg" alt="" />
            </div>
        </div>
        <div class="row Card-Design mt-5">
            <div class="col mt-3">
                <img src="assets/img/graphic-design-1.webp" alt="" />
            </div>
            <div class="col">
                <h2>Business Card Design</h2>
                <p>
                    Elevate your professional image with our exceptional
                    business card design services. Our expert designers will
                    create unique and eye-catching business cards that leave a
                    lasting impression on your clients and prospects. From sleek
                    minimalist designs to bold and creative concepts, we tailor
                    each card to reflect your brand identity and values. Stand
                    out from the crowd and make networking a breeze with our
                    outstanding business card designs.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
        <div class="row Card-Design mt-5">
            <div class="col">
                <h2>Social Media Banner Design</h2>
                <p>
                    Supercharge your online visibility with our eye-catching
                    banner design service. Whether it's for Facebook, Instagram,
                    or any other platform, our team of designers will craft
                    visually stunning banners that demand attention. Captivate
                    your audience with captivating visuals that reflect your
                    brand's personality and drive engagement. With our expertise
                    in creating compelling banners, you'll stand out in the
                    digital landscape and leave a lasting impression on your
                    followers. Elevate your online presence with our
                    attention-grabbing banners today.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/social-media-banner.jpeg" alt="" />
            </div>
        </div>
        <div class="row Card-Design mt-5">
            <div class="col mt-3">
                <img src="assets/img/social-media-post.webp" alt="" />
            </div>
            <div class="col">
                <h2>Social Media Post Graphics</h2>
                <p>
                    Elevate your social media game with our compelling post
                    graphics. Our team of experts is committed to making your
                    posts stand out from the crowd. Engage and resonate with
                    your target audience through visually captivating graphics
                    that convey your message effectively. Stay ahead of the
                    competition and strengthen your brand presence with our
                    impactful post graphics. Let us help you create a strong and
                    lasting connection with your audience, driving engagement
                    and boosting your social media success.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
        <div class="row Card-Design mt-5">
            <div class="col">
                <h2>Figma Design Services</h2>
                <p>
                    At our graphic design studio, we welcome both startups and
                    established brands with open arms. Our services are tailored
                    to meet the unique needs of every client. With unwavering
                    dedication to quality and customer satisfaction, we take
                    pride in bringing your ideas to life through stunning
                    visuals. Let our team of skilled designers elevate your
                    business with creative brilliance. Reach out to us today,
                    and together, we'll embark on a design journey that sets
                    your brand apart, leaving your competition in awe.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/figma-design.webp" alt="" />
            </div>
        </div>
        <div class="row Card-Design mt-5">
            <div class="col mt-3">
                <img src="assets/img/brand-identity.webp" alt="" />
            </div>
            <div class="col">
                <h2>Unleash Your Brand Identity</h2>
                <p>
                    Unlock a distinct brand identity with our logo design
                    services. Your logo is more than just a symbol; it's the
                    face of your business. Our expert designers craft logos that
                    make a lasting mark on your audience, leaving a powerful
                    impression. With elegance and finesse, we ensure your logo
                    embodies the essence of your business, capturing its unique
                    personality. Let us create a logo that speaks volumes about
                    your brand, making it instantly recognizable and
                    unforgettable.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>
<div class="service-section mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 graphic-design-2"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <p>Best</p>
                    <h2 class="mb-0">
                        We Design Or Redesign An Online Platform For Your
                        Business
                    </h2>
                    <p class="mt-0">
                        This will be the first baby step in order to establish
                        your business. You need to create an online platform
                        that will be the face of your business for the world,
                        where people can be able to know more about you. This
                        will give your audiences to explore more about you such
                        as your history, your profile, your experience, your
                        specialty, your contact details, and more.
                    </p>

                    <div class="theme-button">
                        <a
                            href="tel:+1-442-259-2040"
                            class="default-btn me-4 p-2"
                            >Call Expert</a
                        >
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            class="default-btn me-4 p-2"
                            >Schedule a Meeting</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img
                            src="assets/img/graphic-design-3.webp"
                            alt="service details"
                        />
                    </div>

                    <!------------------
                       <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    ----------------------->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li>
                            <a routerLink="/web-design"
                                >WEBSITE DESIGNING <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/app-development"
                                >APP DEVELOPMENT <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/softwere-development"
                                >SOFTWARE DEVELOPMENT
                                <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/graphic-desigining"
                                >GRAPHICS DESIGNING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/digital-marketing"
                                >DIGITAL MARKETING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 graphic-design-4">
                <img src="assets/img/graphic-design-4.webp" alt="" />
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        [(ngModel)]="formData.name"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="Name"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        [(ngModel)]="formData.email"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="Email"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        [(ngModel)]="formData.subject"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Subject"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        [(ngModel)]="formData.message"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                        name="message"
                                        class="message-field"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        placeholder="Message"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Send Message
                                </button>

                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    type="button"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Schedule a Meeting
                                </button>
                            </div>
                        </div>
                    </form>

                    <img
                        src="assets/img/shapes/1.png"
                        class="contact-shape"
                        alt="shape"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
