<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Shipping & Delivery</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Shipping & Delivery</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="privacy-section pt-100">
    <div class="container">
        <h3>Shipping and Delivery Information - Hari Web Infotech Pvt Ltd</h3>
       <p>Thank you for choosing Hari Web Infotech Pvt Ltd for your software development needs. We are committed to providing you with high-quality services and products. Below, you'll find all the essential information regarding shipping and delivery procedures to ensure a smooth and satisfactory experience</p>
     
        <div class="privacy-text">
            <h3>Shipping Options:</h3>               
                    <p>We offer multiple shipping options to cater to various requirements and delivery preferences:</p>
            <ul>
                <li>
                    Standard Shipping: Our standard shipping method ensures reliable and timely delivery of your software development projects. The estimated delivery time for standard shipping is based on delivery location.</li>
                   <li> Express Shipping: For urgent requirements, we provide express shipping services. Your project will be prioritized, and you can expect faster delivery within as soon as possible.</li>

                   <li> Digital Delivery: For certain software products and services, we offer digital delivery options. You will receive your deliverables through secure digital channels, ensuring swift access to your project.
                    Information provided by you to us while using our services or website such as timing, frequency, and pattern of use of service or website</li>
                
            </ul>
        </div>
        <div class="privacy-text">
          

           <li>  Shipping Charges: Our shipping charges are determined based on the shipping method chosen, the destination location, and the size of the software development project. During the checkout process, you will be able to view the applicable shipping charges before finalizing your order.</li> 
            
            

            <li>Order Processing Time: Upon placing an order, we initiate the processing phase promptly. The processing time varies depending on the complexity of your project and any specific requirements. You will receive a confirmation email with an estimated processing time after placing your order.</li>
            
           
            
           
            <li> Tracking Your Order: For shipped orders, we provide tracking information that allows you to monitor the status of your delivery in real-time. Once your order is dispatched, you will receive an email containing a tracking link to stay updated on the delivery progress.</li>
            
            
            
           
            <li>  Delivery Confirmation: After successful delivery, we will send a delivery confirmation notification to your provided email address. If you encounter any issues or have questions regarding your delivery, please feel free to contact our customer support team at support@hariwebinfotech.com. Our team will be happy to assist you.
            </li>
            
          
         

            <li>      International Shipping:   Hari Web Infotech Pvt Ltd offers international shipping services to cater to customers from around the world. Please note that delivery times for international shipments may vary depending on the destination country and customs regulations.</li>
            
      
            
            
            <li>Delivery Terms and Conditions:  While we strive to ensure timely delivery, please be aware that unexpected events, natural disasters, or external factors beyond our control may occasionally cause delays. We will keep you informed in such cases and work diligently to resolve any issues promptly.
                <p>  If you have any questions or concerns about our shipping and delivery policies, please refer to our website's Shipping and Delivery page at hariwebinfotech.com/shipping-and-delivery or contact our customer support team.</p>
                <p>    At Hari Web Infotech Pvt Ltd, your satisfaction is our top priority, and we are committed to making your software development journey a success. We appreciate your trust in us and look forward to serving you with excellence.</p>
            </li>
          
          <li> Thank you for choosing Hari Web Infotech Pvt Ltd!</li>
          
            
        </div>
        
    </div>
</div>