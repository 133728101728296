<app-navbar-one></app-navbar-one>

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Service Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Service  Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-details">
   <div class="container mt-5">
    <div class="row">
        <div class="col">
           <h1>Advanced Website Designing & Development</h1>
           <p>This will be the first baby step to establishing your business. You need to create an online platform that will be the face of your business for the world, where </p>
<ul>
    <li>Website Designing</li>
    <li>App Development</li>
    <li>Graphic Designing</li>
    <li>SEO</li>
    <li>Marketing & Solution</li>
</ul>
<div class="theme-button">
    <a routerLink="/" class="default-btn me-4 p-2">Call Expert</a>
    <a routerLink="/" class="default-btn me-4 p-2">Schedule a Meeting</a>
</div>
          
        </div>
        <div class="col"><img src="assets/img/computer.webp" alt=""></div>
    </div>
   </div>
</section>
<div class="service-section mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 service-img"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <p>Best</p>
                    <h2 class="mb-0">Website Designing Company In Delhi, India</h2>
                    <p class="mt-0">We live in an advanced world, and having a site is necessary for a business, regardless of its enormous or little. On the off chance that you have a business and don’t have a place, that implies you are presumably losing various extraordinary freedoms for your business. A site planned by the best Website Designing Services & Website Designing Company in Delhi, India, can be utilized to achieve various promoting techniques to assist your business in developing. We should view the accompanying details… A fully functional asset for your Website and Digital Marketing needs.</p>
                    <div class="theme-button">
                        <a routerLink="/about" class="default-btn me-4 p-2">Read More</a>
                        <a routerLink="/" class="default-btn me-4 p-2">Schedule a Meeting</a>
                        
                     </div>
                </div>
                
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img src="assets/img/Services.jpeg" alt="service details">
                    </div>
                    
                    <h3>Online Business</h3>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen</p>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                 <!------------------
                       <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    ----------------------->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div>

                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/service-details">Business Strategy <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Online Business <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Business Policy <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Software Developmet <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">Marketing <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/service-details">UI/UX Design <i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>
                   
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                               
                               
                                
                                    <button type="submit" class="default-btn contact-btn me-4 p-2">Send Message</button>
                                   
                                     <button type="submit" class="default-btn contact-btn me-4 p-2">Schedule a Meeting</button>
                              
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>