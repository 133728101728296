import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrls: ['./middleware.component.scss']
})
export class MiddlewareComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Facility Management Software Development");

    this.meta.updateTag({ name: 'description', content: "Unlock efficiency with Hari Web Infotech's cutting-edge Facility Management Software Development solutions. Streamline operations seamlessly. Explore now!" });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
