import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-facilitiesmanagement',
  templateUrl: './facilitiesmanagement.component.html',
  styleUrls: ['./facilitiesmanagement.component.scss']
})
export class FacilitiesmanagementComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Facility Management Software Development in Noida, Delhi");

    this.meta.updateTag({ name: 'description', content: "Enhance efficiency with our top-notch Facility Management Software Development services in Delhi. Tailored solutions for streamlined operations. Contact us now!" });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
