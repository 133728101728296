import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-bussiness-process',
  templateUrl: './bussiness-process.component.html',
  styleUrls: ['./bussiness-process.component.scss']
})
export class BussinessProcessComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Business Process Outsourcing Services in Noida Delhi");

    this.meta.updateTag({ name: 'description', content: "Efficiently outsource tasks to our skilled team. Streamline operations, cut costs, and focus on growth. Your success, our priority." });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
