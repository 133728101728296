<app-navbar-one></app-navbar-one>

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Services</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Services</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="video-section pt-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="video-text">
                    <a href="https://www.youtube.com/channel/UCaUSx__dmGh1ItbhfahH4JA" class="popup-vimeo"><i class='bx bx-play'></i></a>
                    <p>Watch our video and know more about us</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Website Designing Company In Delhi, India</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                                <a href="#"><img src="assets/img/Web-Design-Development.png" alt="signature"></a>
                                <p>Web Design & Development</p>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <a href="#"><img src="assets/img/SEO Services.png" alt="signature"></a>
                                <p>SEO Services</p>
                            </div>
                        </div>
                        
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                               <a href="#"> <img src="assets/img/Mobile App Development.png" alt="signature"></a>
                                <p>Mobile App Development
                                </p>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                               <a href="#"> <img src="assets/img/Campaign Management.png" alt="signature"></a>
                                <p>Campaign Management</p>
                            </div>
                        </div>
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/web-design.jpeg" alt="theory image">
            </div>
        </div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-one">
                    <i class="flaticon-technical-support"></i>
                    <h3>Business Consultancy</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-two">
                    <i class="flaticon-megaphone"></i>
                    <h3>Human Resource</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-three">
                    <i class="flaticon-optimization"></i>
                    <h3>Business Planning</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-four">
                    <i class="flaticon-first-place"></i>
                    <h3>Online Business</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-five">
                    <i class="flaticon-alarm-clock"></i>
                    <h3>Online Marketing</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-card feature-six">
                    <i class="flaticon-newsletter"></i>
                    <h3>Project Managment</h3>
                    <p>When it comes after the noun, the adjective usually doesn't get a hyphen. So we say an easy to remember.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-style-two">
    <div class="container-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="video-text text-center">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class="bx bx-play"></i></a>
                    <p>Watch our video</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="portfolio-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            
            <h2>Latest Works For Clients</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 mix online-business">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/online-business.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 mix digital-marketing">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/digital-marketing.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Digital Marketing</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8 mix online-business">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/online-business2.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Online Business</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 mix writing">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/content-writer.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Content Writing</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-8 mix">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/virtual-assistant.webp" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>Virtual Assistant Service</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-4 mix seo">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="assets/img/seo.jpeg" alt="portfolio image">

                        <div class="portfolio-text">
                            <h3>SEO Service</h3>
                            <a routerLink="/" class="portfolio-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/portfolio" class="default-btn">Load More</a>
        </div>
    </div>
</section>