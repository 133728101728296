import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})
export class CloudComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Cloud App Development Services in Delhi | Cloud Application Development");

    this.meta.updateTag({ name: 'description', content: "Hari Web Infotech is a leading cloud app development company in delhi,India offering end-to-end services from tech consulting to Efficiently build, deploy & manage apps with our Cloud App Development services." });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
