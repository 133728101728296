import { Component } from '@angular/core';

@Component({
  selector: 'app-salesmanagement',
  templateUrl: './salesmanagement.component.html',
  styleUrls: ['./salesmanagement.component.scss']
})
export class SalesmanagementComponent {

}
