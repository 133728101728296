<app-navbar-one></app-navbar-one>

<div class="team-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Our Team</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Our Team</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="team-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Jerry Hudson</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Juho Hudson</h3>
                            <p>Research Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Sarah Tylor</h3>
                            <p>Marketing Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/8.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Aikin Ward</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/7.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Alyssa Hudson</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/6.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Abraham</h3>
                            <p>Research Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Jacey</h3>
                            <p>Marketing Consultant</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/5.jpg" alt="team member">

                        <div class="team-text">
                            <div class="team-social">
                                <ul>
                                    <li><a target="_blank" href="#"><i class="flaticon-facebook"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-twitter"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-instagram"></i></a></li>
                                    <li><a target="_blank" href="#"><i class="flaticon-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <h3>Isabelle</h3>
                            <p>Business Consultant</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>