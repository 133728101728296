import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from '../form/form.component';
import { Width } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { ServicesService } from 'src/app/Services/services.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Title, Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com',
  }
  form:FormData;
  myFunction(ak): void {
    document.getElementById('exampleModalLabel').innerHTML=ak;
  }
  constructor(private dialog: MatDialog,private http: HttpClient,private renderer: Renderer2,private title: Title,private meta: Meta) {
  }
  
  slideConfig = { slidesToShow: 1, slidesToScroll: 1 ,autoplay:true,
    autoplaySpeed:1500,
    arrows:true,};
    slider = [
      { img: 'assets/img/CRMDevelopment.webp' },
      { img: 'assets/img/GraphicDesign.webp' },
      { img: 'assets/img/MobileAppDevelopment.webp' },
      { img: 'assets/img/SEOServices.webp' }
    ];
    sliderConfig = { slidesToShow: 2, slidesToScroll: 1 ,autoplay:true,
      autoplaySpeed:1500,};
  

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FormComponent, {
      width: '100%',
      height:'300px',
     
    });
  }
  successNotification() {
    Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
  }
  ngOnInit(): void {
    this.title.setTitle("Best Website Designing Company in Noida,Delhi");

    this.meta.updateTag({ name: 'description', content: "Hari Web Infotech is the most trusted website designing company in Delhi . The Company is popular for offering creative web designing agency in Delhi, India." });
  }
  resetForm(){
    this.formData= {name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'}
  }
onSubmit(){
  const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
  if(this.formData.email===''){
    this.formData.email="N/A";
  }
   if(this.formData.phone===''){
this.formData.phone='N/A';
  }
   if(this.formData.subject===''){
    this.formData.subject='N/A';
      }
      if(this.formData.serviceType===''){
        this.formData.serviceType='N/A';
          }
  this.http.post(apiUrl,this.formData).subscribe(
    (response)=> {
      // alert("Message Sent Successfully! Our Team Will Contact You Shortly");
     
   this.successNotification();
this.resetForm();
  
      console.log('emailsend');
      
      
    },(error)=>{
      console.log('error',error);
    }
  )
  
}



}
