import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  isLoading: boolean = false;

  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'sales@hariwebinfotech.com'
  }

  constructor(private http: HttpClient,private renderer: Renderer2,private title: Title, private meta: Meta) {

  }
  successNotification() {
    Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
  }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Get in Touch with Our Expert Website Designing Team in Delhi - Contact Us Now");

    this.meta.updateTag({ name: 'description', content: "Expert website designing for your business! Contact us now for captivating, user-friendly websites that leave a lasting impression." });

  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
  resetForm() {
    this.formData =
    {
      name: '',
      phone: '',
      message: '',
      serviceType: '',
      subject: '',
      email: '',
      mailto: 'sales@hariwebinfotech.com'
    }
  }
  onSubmit() {

    this.isLoading = true;

    const apiUrl = 'https://sub.hariwebinfotech.com/public/api/query';
    if (this.formData.email === '') {
      this.formData.email = "N/A";
    }
    if (this.formData.phone === '') {
      this.formData.phone = 'N/A';
    }
    if (this.formData.subject === '') {
      this.formData.subject = 'N/A';
    }
    if (this.formData.serviceType === '') {
      this.formData.serviceType = 'N/A';
    }

    if (this.formData.message === '') {
      this.formData.message = 'N/A';
    }
    this.http.post(apiUrl, this.formData).subscribe(
      (response) => {
        // alert("Message Sent Successfully! Our Team Will Contact You Shortly");

        this.successNotification();
        this.isLoading = false;
        console.log('emailsend', response);

        this.resetForm();
      }, (error) => {
        console.log('error', error);
        this.isLoading = false;
      }
    )
  }
}
