import { Component } from '@angular/core';

@Component({
  selector: 'app-application-developement',
  templateUrl: './application-developement.component.html',
  styleUrls: ['./application-developement.component.scss']
})
export class ApplicationDevelopementComponent {

}
