import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-portfolio-two',
  templateUrl: './portfolio-two.component.html',
  styleUrls: ['./portfolio-two.component.scss']
})
export class PortfolioTwoComponent implements OnInit {

    slider = [
      { img: 'assets/img/www.fitwise.com_.au_.png', },
      { img: 'assets/img/www.rgmsalon.in_.png' },
      { img: 'assets/img/Alcoa-Home.png' },
      { img: 'assets/img/Alliance-Trust-PLC-_-Global-Equities-Investment-Trust.png' },
      { img: 'assets/img/Global-Alliance-of-SMEs.png' },
      { img: 'assets/img/Home-MainStreet-Bank.png' },
      { img: 'assets/img/www.mdsave.com_.png' },
      { img: 'assets/img/www.bblanguages.com_.png' },
      { img: 'assets/img/www.globalcoachinginstitute.orgusa.png' },
      { img: 'assets/img/www.lifecare.com_.au_.png' },
    ];
    sliderConfig = { slidesToShow: 4, slidesToScroll: 1 ,autoplay:false,
      autoplaySpeed:900,};
  

  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }

  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }

}
