import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content-writer',
  templateUrl: './content-writer.component.html',
  styleUrls: ['./content-writer.component.scss']
})
export class ContentWriterComponent {
  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'
  }

  constructor(private http: HttpClient) {

  }
  successNotification() {
    Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
  }
  ngOnInit(): void { }
  onSubmit() {
    const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
    if (this.formData.email === '') {
      this.formData.email = "N/A";
    }
    if (this.formData.phone === '') {
      this.formData.phone = 'N/A';
    }
    if (this.formData.subject === '') {
      this.formData.subject = 'N/A';
    }
    if (this.formData.serviceType === '') {
      this.formData.serviceType = 'N/A';
    }
    this.http.post(apiUrl, this.formData).subscribe(
      (response) => {
        // alert("Message Sent Successfully! Our Team Will Contact You Shortly");

        this.successNotification();
        console.log('emailsend');


      }, (error) => {
        console.log('error', error);
      }
    )
  }

}
