<app-navbar-one></app-navbar-one>

<div class="blog-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Our Blogs</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100 pb-70">
    <div class="container">               
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/1.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>The next genaration IT will change the world</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/2.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>Content writing demand day by day increasing</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/3.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>Don't buy a tech gift until you read these rules image</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/4.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details">
                            <h3>SEO service demand day by day increasing</h3>
                        </a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/5.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>Use images free by following this rule</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/"><img src="assets/img/blog/6.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>How it will affect the next genaration business</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/7.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>The next genaration IT will change the world</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-1.png" alt="blog author">
                            <a routerLink="/"><p>Aikin Ward</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/"><img src="assets/img/blog/8.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>22 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>Content writing demand day by day increasing</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-2.png" alt="blog author">
                            <a routerLink="/"><p>John Smith</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-card">
                    <div class="blog-img">
                        <a routerLink="/"><img src="assets/img/blog/9.jpg" alt="blog image"></a>
                        <div class="blog-date">
                            <span>23 Feb</span>
                        </div>
                    </div>

                    <div class="blog-text">
                        <a routerLink="/blog-details"><h3>Don't buy a tech gift until you read these rules image</h3></a>
                        <div class="post-info">
                            <img src="assets/img/blog/author-3.png" alt="blog author">
                            <a routerLink="/"><p>Sarah Tylor</p></a>
                            <a routerLink="/blog-details" class="blog-btn">Read More <i class="flaticon-right-chevron"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>