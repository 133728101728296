<app-navbar-one></app-navbar-one>
<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>PPC Management</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>PPC Management</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>GOOGLE ADS</h2>
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Google Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Targeted Keywords Research</li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimizatio</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>BING ADS</h2>
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Microsoft Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Targeted Keywords Research</li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimization</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                           
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>FACEBOOK ADS</h2>   
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Facebook Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Interest & Behaviour Based Targetiam</li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimization</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                        </ul>
                    </div>
                    

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</div>
<div class="pricing-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>LINKEDIN ADS</h2>
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>LinkedIn Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Industry Type & Job Level Based Targeting</li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimization</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>YOUTUBE ADS</h2>
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Google Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Channel Based Targeting</li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimization</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>INSTAGRAM ADS</h2>
                        <h3>Starting $179.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Instagram Ads Account Creation</li>
                            <li><i class='bx bx-check'></i>Interest & Behaviour Based Targeting </li>
                            <li><i class='bx bx-check'></i>Landing Page Optimization</li>
                            <li><i class='bx bx-check'></i>Ad Creation</li>
                            <li><i class='bx bx-check'></i>Bid Strategy</li>
                            <li><i class='bx bx-check'></i>A/B Testing</li>
                            <li><i class='bx bx-check'></i>Campaign Optimization</li>
                            <li><i class='bx bx-check'></i>Daily Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                        </ul>
                    </div>
                    

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
