<app-navbar-one></app-navbar-one>
<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Social Media Marketng</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Social Media Marketng</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>FACEBOOK MARKETING</h2>
                        <h3><span>Starting $99.99</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Facebook Business Page</li>
                            <li><i class='bx bx-check'></i>Page Optimization</li>
                            <li><i class='bx bx-check'></i>Facebook Marketplace Set-up</li>
                            <li><i class='bx bx-check'></i>Traffic Building Strategies & Hashtags</li>
                            <li><i class='bx bx-check'></i>Organic Post Writing Services</li>
                            <li><i class='bx bx-check'></i>Facebook Ad Campaign Set-up</li>
                            <li><i class='bx bx-check'></i>Creative Facebook Banners & Stories</li>
                            <li><i class='bx bx-check'></i>Organic Likes & Followers</li>
                            <li><i class='bx bx-check'></i>Analytics progress report</li>
                            <li><i class='bx bx-check'></i>Keyword Based Content for Postings</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i>Monthly Activity Report</li>
                           
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>INSTAGRAM MARKETING</h2>
                        <h3>Starting $99.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Instagram Business Page</li>
                            <li><i class='bx bx-check'></i>Page Optimization</li>
                            <li><i class='bx bx-check'></i> Instagram Marketplace Set-up </li>
                            <li><i class='bx bx-check'></i> Traffic Building Strategies & Hashtags</li>
                            <li><i class='bx bx-check'></i> Organic Post Writing Services</li>
                            <li><i class='bx bx-check'></i> Instagram Ad Campaign Set-up</li>
                            <li><i class='bx bx-check'></i> Creative Instagram Posts & Stories</li>
                            <li><i class='bx bx-check'></i> Organic Followers</li>
                            <li><i class='bx bx-check'></i>Analytics progress report</li>
                            <li><i class='bx bx-check'></i>Creation Of Hash Tags</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i>Monthly Activity Report</li>
                           
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>LINKEDIN MARKETING</h2>   
                        <h3> Starting $99.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>LinkedIn Business Page</li>
                            <li><i class='bx bx-check'></i>Keyword Based Content For Posting</li>
                            <li><i class='bx bx-check'></i>Adding Thematic Connections</li>
                            <li><i class='bx bx-check'></i>Traffic Building Strategies & Hashtags</li>
                            <li><i class='bx bx-check'></i>Business Page Content Writing</li>
                            <li><i class='bx bx-check'></i>Page Optimization</li>
                            <li><i class='bx bx-check'></i>Promoting Brand In Targetted Audience</li>
                            <li><i class='bx bx-check'></i>Organic Followers & Connections</li>
                            <li><i class='bx bx-check'></i>Message Reply And report</li>
                            <li><i class='bx bx-check'></i>Monthly Activity Report</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i>Campa. Set up</li>
                        </ul>
                    </div>
                    

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</div>
<div class="pricing-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>YOUTUBE MARKETING</h2>
                        <h3>Starting $99.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Youtube Channel Creation</li>
                            <li><i class='bx bx-check'></i>Youtube Ad Account Optimization</li>
                            <li><i class='bx bx-check'></i>Channel Design, Image, Customizing</li>
                            <li><i class='bx bx-check'></i>Custom Thumbnail Creation</li>
                            <li><i class='bx bx-check'></i>Video Animation & Graphics</li>
                            <li><i class='bx bx-check'></i>Youtube Ad Campaign Set-up</li>
                            <li><i class='bx bx-check'></i>Youtube Keyword Search</li>
                            <li><i class='bx bx-check'></i>Youtube Backlink Creation</li>
                            <li><i class='bx bx-check'></i>Analytics progress report</li>
                            <li><i class='bx bx-check'></i>Organic Subscribers & Likes</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i>Monthly Activity Report</li>
                            
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>TWITTER MARKETING</h2>
                        <h3>Starting $99.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Twitter Business Page</li>
                            <li><i class='bx bx-check'></i>Page Optimization</li>
                            <li><i class='bx bx-check'></i>Organic Tweet Writing Services</li>
                            <li><i class='bx bx-check'></i>Traffic Building Strategies & Hashtags</li>
                            <li><i class='bx bx-check'></i>Organic Post Writing Services</li>
                            <li><i class='bx bx-check'></i>Ad Campaign Set-up</li>
                            <li><i class='bx bx-check'></i>Creative Twitter Tweets & Stories</li>
                            <li><i class='bx bx-check'></i>Key Influencers Tracking</li>
                            <li><i class='bx bx-check'></i>Organic Follwers</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i>Monthly Activity Report</li>
                          
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>BUSINESS BOSTER SERVICES
                        </h2>
                        <h3>Starting $499.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Any 5 Social Media Platforms</li>
                            <li><i class='bx bx-check'></i>Complete Marketing Solution</li>
                            <li><i class='bx bx-check'></i>Customised Business Strategy Execution</li>
                            <li><i class='bx bx-check'></i>Organic Traffic Building Likes</li>
                            <li><i class='bx bx-check'></i>Followers</li>
                            <li><i class='bx bx-check'></i>In-depth Competitor Analysis & Implementation</li>
                            <li><i class='bx bx-check'></i>Ad Campaign Optimization Strategies</li>
                            <li><i class='bx bx-check'></i>Content Marketing Strategy & Posts </li>
                            <li><i class='bx bx-check'></i>Community Engagement Managemen</li>
                            <li><i class='bx bx-check'></i>Social Media Ad Campaigns Optimization</li>
                            <li><i class='bx bx-check'></i>Website Optimization</li>
                            <li><i class='bx bx-check'></i>Dedicated Account Manager</li>
                            <li><i class='bx bx-check'></i> Weekly Reports</li>
                          
                        </ul>
                    </div>
                    

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
