<app-navbar-one></app-navbar-one>

<div class="blog-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Blog Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Blog Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-description">
                    <div class="article-image">
                        <img src="assets/img/blog-1.png" alt="blog image">
                        <div class="blog-date">
                            <span>21 Feb</span>
                        </div>
                    </div>
                   
                    <div class="article-info">
                        <ul>
                            <li><i class="icofont-user-alt-2"></i> Shubham</li>
                            <li><i class="icofont-user-alt-2"></i>  November 3, 2020 by </li>
                            <li><i class="icofont-user-alt-2"></i> Marketing</li>
                        </ul>
                    </div>
                    <h3 class="article-title">Online business with email marketing</h3>
                    <p>Email short name of electronic mail is the best medium to exchange messages through electronic devices. This is one of the oldest methods of exchanging messages via electronic medium which are trustworthy and secure. With the enhancement in the technology and business world, it becomes a vital part of online marketing strategy.</p>
                    <h3 class="article-title">Significance of email marketing   </h3>
                    <p>Email marketing has shown a great impact on the online businesses and is being used to promote a business, products, or services. It brings awareness among your customers who are on your email list about discounts, offers, new products, and other services. It also helps in engaging your customers between purchase and acts as a soft sell to educate your audience about your brand value. If you want to expand your business or reach a wider audience group, email marketing is considered as the most effective and popular method.</p>
                    <h3 class="article-title">Email Marketing keeps your audiences updated</h3>
                    <p>Newsletter is also a part of EMial marketing that contains updates of the company, promotions, sales, new exclusive deals, and so on. The email campaign can also cover the creating and sharing of general messages from the business such as how to deal with the natural disaster or company scandal or more. All in all, email marketing is the best way to keep audiences and customers informed about the business.</p>
                    
                    <div class="row">
                        <div class="col-sm-6">
                            <img src="assets/img/blog-9.jpg" class="article-bottom-img" alt="blog image">
                        </div>
                        <div class="col-sm-6">
                            <img src="assets/img/blog-10.jpg" class="article-bottom-img" alt="blog image">
                        </div>
                    </div>
                    <h3 class="article-title">What are the important objectives of Email Marketing?</h3>
                    <p>Every tool of the digital marketing is based on some objective and goals. Email marketing campaign is based on proper strategy and the right agenda which is always focused on certain objectives. Here are some popular points:</p>
                    <div class="blog-nav">
                        <div class="prev-btn">
                            <a href="#">Previous</a>
                        </div>

                        <div class="next-btn text-end">
                            <a href="#">Next</a>
                        </div>
                    </div>
                    <div class="blog-comment">
                        <h3>Drop your comment</h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Full Name">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Your Email">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                            <div class=" col-md-12">
                                <div class="form-group">
                                    <textarea class="message-field" cols="30" rows="5" placeholder="Comment Here"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                               
                                <button type="submit" class="default-btn contact-btn me-4 p-2">Post A Comment</button>
                               
                                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn contact-btn me-4 p-2" >Schedule a Meeting</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="blog-search">
                    <form>
                        <input type="text" placeholder="Search">
                        <button><i class="flaticon-search"></i></button>
                    </form>
                </div>

                <div class="recent-blog">
                    <h3>Recent Blogs</h3>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Content writing demand day by day increasing</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-2.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">Don't buy a tech gift until you read these rules image</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>

                    <article class="recent-post">
                        <a routerLink="/blog"><img src="assets/img/blog/recent-3.png" alt="recent post image"></a>
                        <h3><a routerLink="/blog">The next genaration IT will change the world</a></h3>
                        <ul>
                            <li><i class="icofont-user-alt-3"></i> Admin</li>
                            <li><i class="icofont-user-alt-3"></i> Jan 03, 2023</li>
                        </ul>
                    </article>
                </div>

                <div class="blog-category">
                    <h3>Category</h3>

                    <ul>
                        <li><a routerLink="/web-design">WEBSITE DESIGNING <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/app-development">APP DEVELOPMENT <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/softwere-development">SOFTWARE DEVELOPMENT <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/graphic-desigining">GRAPHICS DESIGNING<i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/digital-marketing">DIGITAL MARKETING<i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
                
                <div class="tags">
                    <h3>Tags</h3>
                    <a routerLink="/blog">UX/UX</a>
                    <a routerLink="/blog">Web</a>
                    <a routerLink="/blog">App</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Business</a>
                    <a routerLink="/blog">UX</a>
                    <a routerLink="/blog">Design</a>
                    <a routerLink="/blog">Web</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--start modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header ">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Schedule a Meeting</h1>
          <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body contact-section">
     
              <div class="contact-form p-4 pb-0 pt-0">
                  <form id="contactForm">
                      <div class="row">
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="name" class="name">Name</label>
                                  <input type="text" name="name" id="name" class="form-control" placeholder="">
                              </div>
                          </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                            <div class="form-group">
                                <label for="number" >Mobile Number</label>
                                <input type="text" name="number" id="number" class="form-control" placeholder="">
                            </div>
                        </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="email">Email</label>
                                  <input type="email" name="email" id="email" class="form-control" placeholder="">
                              </div>
                          </div>
                          <div class="col-md-12 col-sm-6 mt-1">
                            <div class="form-group">
                                <label for="service">Service</label>
                                <select class="form-select form-control" aria-label="Default select example">
                                    <option >Select</option>
                                    <option value="1">Website Designing</option>
                                    <option value="2">App Development</option>
                                    <option value="3">Software Development</option>
                                    <option value="3">Graphic Designing</option>
                                    <option value="3">Digital Marketing</option>
                                  </select>
                            </div>
                          </div>
                          
                          <div class="col-md-12 col-sm-6 mt-1">
                              <div class="form-group">
                                  <label for="message-text" class="col-form-label">Message</label>
                              <textarea class="form-control" id="message-text"></textarea>
                              </div>
                          </div>
                      </div>
                      <div class="row p-3">
                        <button type="button" class="default-btn contact-btn me-2 pt-2 pb-2">Send</button>
                      </div>
                        
                  </form>
  
              </div>
        </div>
        
      </div>
    </div>
  </div>
  <!-- end modal -->