<app-navbar-one></app-navbar-one>
<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Content Writer</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Content Writer</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>BASIC</h2>
                        <h3>Starting $99.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Page Web Content Writing</li>
                            <li><i class='bx bx-check'></i>Articles Content Writing</li>
                            <li><i class='bx bx-check'></i>Email Structure and Formatting</li>
                            <li><i class='bx bx-check'></i>Blogs Content Writing</li>
                            <li><i class='bx bx-check'></i>Website Copywriting Service</li>
                            <li><i class='bx bx-check'></i>SEO Copywriting Services 7.24x7 Customer Support</li>
                            <li><i class='bx bx-check'></i>Curating content for Social Media Posts</li>
                            <li><i class='bx bx-check'></i>Analyzing keywords for SEO to maximize traffic</li>
                            <li><i class='bx bx-check'></i>Team of experienced Native English speakers to ensure quality content</li>
                            <li><i class='bx bx-check'></i>Improvisations and revisions</li>
                            
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>ADVANCE</h2>
                        <h3>Starting $299.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Page Web Content Writing</li>
                            <li><i class='bx bx-check'></i>Articles Content Writing</li>
                            <li><i class='bx bx-check'></i>Advanced Email Structure and Formatting</li>
                            <li><i class='bx bx-check'></i>Blogs Content Writing</li>
                            <li><i class='bx bx-check'></i>Website Copywriting Service</li>
                            <li><i class='bx bx-check'></i>SEO Copywriting Services 7.24x7 Customer Support</li>
                            <li><i class='bx bx-check'></i>Curating content for Social Media Posts</li>
                            <li><i class='bx bx-check'></i>Analyzing keywords for SEO to maximize traffic</li>
                            <li><i class='bx bx-check'></i>Team of experienced Native English speakers to ensure quality content</li>
                            <li><i class='bx bx-check'></i>Improvisations and revisions</li>
                           
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>PREMIUM</h2>   
                        <h3>Starting $599.99</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Page Web Content Writing</li>
                            <li><i class='bx bx-check'></i>Articles Content Writing</li>
                            <li><i class='bx bx-check'></i>Premium Email Structure and Formatting</li>
                            <li><i class='bx bx-check'></i>Blogs Content Writing</li>
                            <li><i class='bx bx-check'></i>Website Copywriting Service</li>
                            <li><i class='bx bx-check'></i>SEO Copywriting Services 7.24x7 Customer Support</li>
                            <li><i class='bx bx-check'></i>Curating content for Social Media Posts</li>
                            <li><i class='bx bx-check'></i>Analyzing keywords for SEO to maximize traffic</li>
                            <li><i class='bx bx-check'></i>Team of experienced Native English speakers to ensure quality content</li>
                            <li><i class='bx bx-check'></i>Improvisations and revisions</li>
                        </ul>
                    </div>
                    

                    <div class="theme-button text-center">
                        <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</div>
