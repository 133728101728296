import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-erp',
  templateUrl: './erp.component.html',
  styleUrls: ['./erp.component.scss']
})
export class ErpComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Best Custom ERP Development Services in Delhi");

    this.meta.updateTag({ name: 'description', content: "Boost efficiency with our top-notch Custom ERP Development Services in Delhi at Hari Web Infotech. Tailored solutions for seamless business operations. Explore now" });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
