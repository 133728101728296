import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';

import { NavbarOneComponent } from './components/layouts/navbar-one/navbar-one.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { PortfolioTwoComponent } from './components/pages/portfolio-two/portfolio-two.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogThreeComponent } from './components/pages/blog-three/blog-three.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatDialogModule } from '@angular/material/dialog';
import { FormComponent } from './components/pages/form/form.component';
import { WebDesignComponent } from './components/pages/web-design/web-design.component';
import { AppDevelopmentComponent } from './components/pages/app-development/app-development.component';
import { SoftwereDevelopmentComponent } from './components/pages/softwere-development/softwere-development.component';
import { GraphicDesiginingComponent } from './components/pages/graphic-desigining/graphic-desigining.component';

import { WebDevelopmentComponent } from './components/pages/pricing/web-development/web-development.component';
import { SocialMediaMarketingComponent } from './components/pages/pricing/social-media-marketing/social-media-marketing.component';
import { PpcManagmentComponent } from './components/pages/pricing/ppc-managment/ppc-managment.component';
import { ContentWriterComponent } from './components/pages/pricing/content-writer/content-writer.component';
import { TermsPolicyComponent } from './components/pages/terms-policy/terms-policy.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShippingComponent } from './components/pages/shipping/shipping.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';

import { ConstructionComponent } from './components/pages/industries/construction/construction.component';
import { ElearningComponent } from './components/pages/industries/elearning/elearning.component';
import { FinanceComponent } from './components/pages/industries/finance/finance.component';
import { HealthcareComponent } from './components/pages/industries/healthcare/healthcare.component';
import { MeetingeventsComponent } from './components/pages/industries/meetingevents/meetingevents.component';
import { NonprofitComponent } from './components/pages/industries/nonprofit/nonprofit.component';
import { PaymentsComponent } from './components/pages/industries/payments/payments.component';
import { RealestateComponent } from './components/pages/industries/realestate/realestate.component';
import { RetailComponent } from './components/pages/industries/retail/retail.component';
import { SupplychainComponent } from './components/pages/industries/supplychain/supplychain.component';
import { TeleconmmunicationComponent } from './components/pages/industries/teleconmmunication/teleconmmunication.component';
import { TransportationComponent } from './components/pages/industries/transportation/transportation.component';
import { HospitalityComponent } from './components/pages/industries/hospitality/hospitality.component';
import { SliderComponent } from './components/pages/slider/slider.component';
import { LogosliderComponent } from './components/pages/logoslider/logoslider.component';
import { AnimationgraphicComponent } from './components/pages/solutions/animationgraphic/animationgraphic.component';
import { CloudComponent } from './components/pages/solutions/cloud/cloud.component';
import { CustomcrmComponent } from './components/pages/solutions/customcrm/customcrm.component';
import { ManageddbComponent } from './components/pages/solutions/manageddb/manageddb.component';
import { DigitalmarketingComponent } from './components/pages/solutions/digitalmarketing/digitalmarketing.component';
import { ErpComponent } from './components/pages/solutions/erp/erp.component';
import { FacilitiesmanagementComponent } from './components/pages/solutions/facilitiesmanagement/facilitiesmanagement.component';
import { MiddlewareComponent } from './components/pages/solutions/middleware/middleware.component';
import { MobiletabletComponent } from './components/pages/solutions/mobiletablet/mobiletablet.component';
import { SalesmanagementComponent } from './components/pages/solutions/salesmanagement/salesmanagement.component';
import { TicketingComponent } from './components/pages/solutions/ticketing/ticketing.component';
import { WebdevelopementComponent } from './components/pages/solutions/webdevelopement/webdevelopement.component';
import { ApiDevelopmentComponent } from './components/pages/services/api-development/api-development.component';
import { ApplicationDevelopementComponent } from './components/pages/services/application-developement/application-developement.component';
import { ApplicationMaintenanceComponent } from './components/pages/services/application-maintenance/application-maintenance.component';
import { BussinessProcessComponent } from './components/pages/services/bussiness-process/bussiness-process.component';
import { CustomSoftwareServicesComponent } from './components/pages/services/custom-software-services/custom-software-services.component';
import { DevopsComponent } from './components/pages/services/devops/devops.component';
import { ImplementationDeploymentComponent } from './components/pages/services/implementation-deployment/implementation-deployment.component';
import { MigrationUpgradesComponent } from './components/pages/services/migration-upgrades/migration-upgrades.component';
import { QaTestigComponent } from './components/pages/services/qa-testig/qa-testig.component';
import { SoftwareSecurityComponent } from './components/pages/services/software-security/software-security.component';
import { SystemsIntegrationComponent } from './components/pages/services/systems-integration/systems-integration.component';
import { OurworkComponent } from './components/pages/ourwork/ourwork.component';
import { SitemapComponentComponent } from './sitemap-component/sitemap-component.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    FooterComponent,
    PreloaderComponent,
    NavbarOneComponent,
    NavbarTwoComponent,
    AboutComponent,
    ServiceComponent,
    ServiceDetailsComponent,
    PortfolioComponent,
    PortfolioTwoComponent,
    PricingComponent,
    ErrorComponent,
    FaqComponent,
    TeamComponent,
    TeamTwoComponent,
    PrivacyComponent,
    BlogComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    BlogDetailsComponent,
    ContactComponent,
    FormComponent,
    WebDesignComponent,
    AppDevelopmentComponent,
    SoftwereDevelopmentComponent,
    GraphicDesiginingComponent,
    
    WebDevelopmentComponent,
    SocialMediaMarketingComponent,
    PpcManagmentComponent,
    ContentWriterComponent,
    TermsPolicyComponent,
    RefundPolicyComponent,
    ShippingComponent,
    CheckoutComponent,
    
    ConstructionComponent,
    ElearningComponent,
    FinanceComponent,
    HealthcareComponent,
    MeetingeventsComponent,
    NonprofitComponent,
    PaymentsComponent,
    RealestateComponent,
    RetailComponent,
    SupplychainComponent,
    TeleconmmunicationComponent,
    TransportationComponent,
    HospitalityComponent,
    SliderComponent,
    LogosliderComponent,
    AnimationgraphicComponent,
    CloudComponent,
    CustomcrmComponent,
    ManageddbComponent,
    DigitalmarketingComponent,
    ErpComponent,
    FacilitiesmanagementComponent,
    MiddlewareComponent,
    MobiletabletComponent,
    SalesmanagementComponent,
    TicketingComponent,
    WebdevelopementComponent,
    ApiDevelopmentComponent,
    ApplicationDevelopementComponent,
    ApplicationMaintenanceComponent,
    BussinessProcessComponent,
    CustomSoftwareServicesComponent,
    DevopsComponent,
    ImplementationDeploymentComponent,
    MigrationUpgradesComponent,
    QaTestigComponent,
    SoftwareSecurityComponent,
    SystemsIntegrationComponent,
    OurworkComponent,
    SitemapComponentComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    CarouselModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    
   
  
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
