import { Component } from '@angular/core';

@Component({
  selector: 'app-logoslider',
  templateUrl: './logoslider.component.html',
  styleUrls: ['./logoslider.component.scss']
})
export class LogosliderComponent {

    slider = [
      { img: 'assets/img/portfolio/unidemy.webp' },
      { img: 'assets/img/portfolio/1008.webp' },
      { img: 'assets/img/portfolio/jyotirmatha.webp' },
      { img: 'assets/img/portfolio/CTB.webp' },
      { img: 'assets/img/portfolio/ctvnews.webp' },
      { img: 'assets/img/portfolio/ebook-trip.webp' },
      { img: 'assets/img/portfolio/fabbag.webp' },
      { img: 'assets/img/portfolio/EBT.webp' },
      { img: 'assets/img/portfolio/newu.webp' },
      { img: 'assets/img/portfolio/flywebspace.webp' },
      { img: 'assets/img/portfolio/neeta.webp' },
      { img: 'assets/img/portfolio/mts.webp' },
      { img: 'assets/img/portfolio/wilsons.webp' },
      { img: 'assets/img/portfolio/Thetravelhike.webp' },
      
    ];
    sliderConfig = { slidesToShow: 6, slidesToScroll: 1 ,autoplay:true,
      cssEase: 'linear',
      autoplaySpeed: 0,
      speed: 3000,
      dots: false,
    prevArrow: false,
    nextArrow: false,
     responsive: [
      {
      breakpoint: 767,
      settings:"unslick",
      },{
           breakpoint: 767,
           settings:{
              slidesToShow: 3,
              slidesToScroll: 1
           }
        }
     ]
    };
  slickInit(e: any) {
    
  }
  breakpoint(e: any) {
    
  }
  afterChange(e: any) {
  
  }
  beforeChange(e: any) {
   
  }
}
