import { Component } from '@angular/core';

@Component({
  selector: 'app-supplychain',
  templateUrl: './supplychain.component.html',
  styleUrls: ['./supplychain.component.scss']
})
export class SupplychainComponent {

}
