<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>CUSTOM TELECOM SOFTWARE DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">
        <div class="col-sm-6 contentOne">
            <h3>Business Support Systems (BSS)</h3>
<p>We build custom Business Support Systems (BSS) to manage your products, orders, revenue stream, and customer management processes, equipped with tailor-made platforms, applications, tools, and systems, including:</p>
<ul>
<li>CRM Systems</li>
<li>ERP Software Solutions</li>
<li>Billing Systems</li>
<li>Customer-Self Service Portals</li>
<li>Personnel Management Corporate Portals</li>
<li>Predictive Analytics Solutions</li>
<li>Report Generation & Data Visualization Tools</li>
<li>Sales & Lead Generation Automation Systems</li>
</ul>
        </div>
        <div class="col-sm-6">
            <img src="assets/img/bss.png" alt="">
        </div>
    </div>
</div>

<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Enhance Communication Networks with Telecom Software</span>
                        <h2>Revolutionizing Telecom Through Custom Software Solutions</h2>
                    </div>

                   
                   <p>
                    Welcome to the forefront of telecom innovation with Hari Web Infotech Pvt Ltd's Custom Telecom Software Development Services. We specialize in crafting tailored software solutions that revolutionize communication networks, enhance customer experiences, and drive operational excellence.
                    <br/><br/>
                    Our expert team of developers and telecom industry professionals collaborate seamlessly to create cutting-edge software that addresses the unique challenges of the telecommunications sector. From billing and OSS/BSS systems to network management and customer relationship management (CRM) solutions, our offerings are designed to empower telecom companies to thrive in today's dynamic landscape.
                    <br/><br/>
                    With a deep understanding of the telecom industry's intricacies, we deliver customizable software that seamlessly integrates into your existing infrastructure. Our user-centric designs ensure intuitive interfaces, promoting swift adoption by both your team and end-users.
                    <br/><br/>
                    Experience the future of telecom with Hari Web Infotech Pvt Ltd. Contact us today to explore how our Custom Telecom Software Development Services can optimize your operations, elevate customer experiences, and position your telecom business for success in a rapidly evolving digital world.


                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








