<app-navbar-one></app-navbar-one>
<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Web Development</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Web Development</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
               
                   <img src="assets/img/price1.webp"/>
                
            </div>

            <div class="col-lg-4 col-sm-6">
                <img src="assets/img/price3.webp"/>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <img src="assets/img/price2.webp"/>
            </div>
        </div>
      
    </div>
</div>
<div class="pricing-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <img src="assets/img/price4.webp"/>
            </div>

            <div class="col-lg-4 col-sm-6">
                <img src="assets/img/price6.webp"/>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <img src="assets/img/price5.webp"/>
            </div>
        </div>
    </div>
</div>
