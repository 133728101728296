<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Privacy & Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Privacy & Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="privacy-section pt-100">
    <div class="container">
        <h3>Privacy Policy for Hari Web Infotech</h3>
        <p>Hariweb Infotech is one of the pioneer Information technology consulting or service provider and apprehends that visitors and consumers are the reason for success. We understand the importance of Privacy to individuals and respect your privacy on our website.</p>
        <p>While browsing our site, you may be need to submit your personal information such as your name, email address, contact number, and other details to continue the service requested by you. We have prepared our privacy policy carefully in order to ensure everyone who visits our website that we do care about your personal information and data.</p>
        <p>This privacy policy is applicable to both our visitors or customers, and our websites. It governs the data collected by us, its processing, and usage practices. This privacy policy contains the information regarding the types of personal information collected by us and how we use that information.</p>
        <p>Also, by using our website you automatically consent to the data practices described below. We are requesting everyone to go through our privacy policy before navigating to other pages of our website. If you do not agree with the data practices described below you must not use our website.</p>
        <p>We can update this privacy policy anytime with or without any prior notice, so please revisit it at regular interval. If you want to know more about our privacy policy or you have any queries regarding our privacy policy, please feel free to contact us through email at hari@gmail.com</p>
     
        <div class="privacy-text">
            <h3>Privacy Policy for Hari Web Infotech</h3>               
                        
            <ul>
                <li>
                    Hariweb InfoTech may collect, ask, store, and use these personal information:</li>
                <li>Information about your computer such as IP address, operating systems, browser type and version, etc.</li>
                <li>
                    Information about use of this website such as length of visit, pages viewed, navigation paths, etc.</li>
                <li>
                    Information provided by you to us while using our services or website such as timing, frequency, and pattern of use of service or website</li>
                <li>
                    Information enclosed in or related to any communication happening between us, either by you or us including communication content, Meta data of the communication, etc.</li>
                <li>
                    Any other kind of information or data you may send us.</li>
                <li>In case you are sharing with us information about any other third person, you must obtain his/her consent for disclosure and the processing of that information. Otherwise, we are not responsible to any disclosure or processing of such information provided by you.</li>
            </ul>
        </div>
        <div class="privacy-text">
            <h3>How We Use Your Personal Information</h3>
            <ul>
                <li>Personalize website or services for you</li>
                <li>Administer website or services or business</li>
                    <li>Enable your use of the services</li>
                    <li>Send you marketing contents related to our business or business of our third party which would be found related to your interest via communication channel chosen by you.</li>
                    <li>Send you non marketing contents</li>
                    <li>Provide statistical information about your users’ (not including individual user information) to third parties. Third party will not be able to identify the individual from the information</li>
                    <li>Deal with the enquiries or queries or complaints submitted by you or related to our site or services or business</li>
                    <li>Keep Hariweb InfoTech safe, secure, and free from any fraudulent case</li>
                    <li>We will not supply or share your personal information to any third party so that they or other third party can use the information for direct marketing purpose.</li>
            </ul>
        </div>
        <div class="privacy-text">
            <h3>Your Rights</h3>
            <li>Hariweb Infotech has the right to withhold information as permitted by law.</li>
            <li>You can request/direct us any time to not use your personal information for the purpose of marketing.</li>
                <li>You will expressly agree beforehand to the use of personal information for the purpose of marketing or Hariweb Infotech will give you an option to opt out of the use of information.</li>
        </div>
    </div>
</div>