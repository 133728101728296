import { Component } from '@angular/core';

@Component({
  selector: 'app-teleconmmunication',
  templateUrl: './teleconmmunication.component.html',
  styleUrls: ['./teleconmmunication.component.scss']
})
export class TeleconmmunicationComponent {

}
