import { Component } from '@angular/core';

@Component({
  selector: 'app-customcrm',
  templateUrl: './customcrm.component.html',
  styleUrls: ['./customcrm.component.scss']
})
export class CustomcrmComponent {

}
