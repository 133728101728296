<app-navbar-one></app-navbar-one>
<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>App Development</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>App Development</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-details">
    <div class="container mt-5">
        <div class="row comprehensive mt-5">
            <div class="col">
                <h2>A comprehensive Android Solution</h2>
                <p>
                    Discover the ultimate Android solution with our
                    comprehensive services. From concept to implementation, we
                    provide end-to-end Android app development that meets your
                    business needs. Our expert team crafts cutting-edge
                    applications with user-centric design, seamless performance,
                    and intuitive interfaces. Whether you're a startup or an
                    established brand, our comprehensive Android solution
                    ensures your app stands out in the competitive market,
                    driving growth and success. Experience the power of
                    innovation with our top-notch Android development services
                    today.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/app-development-1.webp" alt="" />
            </div>
        </div>
        <div class="row comprehensive mt-5">
            <div class="col mt-3">
                <img src="assets/img/app-service-1.webp" alt="" />
            </div>
            <div class="col">
                <h2>Beyond Development, Building Partnerships</h2>
                <p>
                    Embrace the cool creativity of Hari Web Infotech Pvt Ltd and
                    embark on an app development journey like never before.
                    Let's redefine your digital presence with apps that set new
                    standards in the industry. Reach out to us today, and
                    together, we'll create apps that not only meet your
                    expectations but exceed them. Trust us to elevate your
                    business with innovation and style!
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
        <div class="row comprehensive mt-5">
            <div class="col">
                <h2>Reliability at its Core</h2>
                <p>
                    At Hari Web Infotech, we place a premium on app reliability.
                    Our app development process includes rigorous testing and a
                    robust architecture to ensure flawless performance under any
                    circumstance. We understand that a reliable app is crucial
                    for user satisfaction and business success. By prioritizing
                    reliability, we guarantee that your app delivers a seamless
                    experience to users, instilling confidence and trust in your
                    brand. Rest assured, your app will perform flawlessly,
                    making it a true asset to your business.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/app-service-2.webp" alt="" />
            </div>
        </div>
        <div class="row comprehensive mt-5">
            <div class="col mt-3">
                <img src="assets/img/app-service-3.webp" alt="" />
            </div>
            <div class="col">
                <h2>User-Centric Design</h2>
                <p>
                    At Hari Web Infotech, we prioritize user satisfaction above
                    all else. Our apps go beyond mere visual appeal; they are
                    meticulously designed with the user in mind. Intuitive
                    interfaces and seamless navigation make for a delightful
                    user experience, encouraging users to return for more. We
                    understand the importance of user-centric design in building
                    successful apps, and our focus on usability ensures that
                    your app becomes a favorite among users, driving engagement
                    and loyalty.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
        <div class="row comprehensive mt-5">
            <div class="col">
                <h2>Crafting Digital Marvels</h2>
                <p>
                    Experience the pinnacle of app innovation with us. Our team
                    merges creativity and cutting-edge technology to craft apps
                    that redefine user experiences. Each app we create is a
                    digital masterpiece, intricately tailored to cater to your
                    unique requirements and preferences. We take pride in
                    delivering apps that captivate users and leave a lasting
                    impression. Elevate your digital presence with our visionary
                    approach to app development, and witness the magic of
                    user-centric design firsthand.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col mt-3">
                <img src="assets/img/app-service-4.webp" alt="" />
            </div>
        </div>
        <div class="row comprehensive mt-5">
            <div class="col mt-3">
                <img src="assets/img/app-service-5.jpg" alt="" />
            </div>
            <div class="col">
                <h2>Unleashing Potential</h2>
                <p>
                    At our core, we are innovators and dreamers. We thrive on
                    pushing boundaries and seeking new frontiers in app
                    development. Our mission is to create apps that go beyond
                    mere functionality; they are transformative tools that
                    unlock the full potential of your business. From
                    revolutionizing workflows to enhancing customer engagement,
                    our apps are designed to drive growth, efficiency, and
                    success. Let us propel your business to new heights with our
                    visionary app solutions.
                </p>
                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>
<div class="service-section mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 app-development-2"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <p>Best</p>
                    <h2 class="mb-0">Our Methodology</h2>
                    <li>
                        Your project requirement analysis We will review your
                        project requirements and talk about your specific
                        concerns before initating any development procedure
                    </li>
                    <li>
                        Discuss with you about your projects Our analyst will
                        consult you to understand your project goals to develop
                        a proper strategy for your requirement.
                    </li>
                    <li>
                        Development timelines According to your requirement and
                        project complexity, we will come up with a project
                        timeline for its accomplishment.
                    </li>
                    <li>
                        Initiation of project development Our developers and
                        designers will start with the development work once you
                        have made your payment based on the terms and conditions
                        we have decided.
                    </li>

                    <div class="theme-button">
                        <a
                            href="tel:+1-442-259-2040"
                            class="default-btn me-4 p-2"
                            >Call Expert</a
                        >
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            class="default-btn me-4 p-2"
                            >Schedule a Meeting</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img
                            src="assets/img/app-development-3.webp"
                            alt="service details"
                        />
                    </div>

                    <!------------------
                       <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    ----------------------->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li>
                            <a routerLink="/web-design"
                                >WEBSITE DESIGNING <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/app-development"
                                >APP DEVELOPMENT <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/softwere-development"
                                >SOFTWARE DEVELOPMENT
                                <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/graphic-desigining"
                                >GRAPHICS DESIGNING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/digital-marketing"
                                >DIGITAL MARKETING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 app-development-4">
                <img src="assets/img/app-development-4.webp" alt="" />
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        [(ngModel)]="formData.name"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="Name"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="Email"
                                        [(ngModel)]="formData.email"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Subject"
                                        [(ngModel)]="formData.subject"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        name="message"
                                        class="message-field"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        placeholder="Message"
                                        [(ngModel)]="formData.message"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Send Message
                                </button>

                                <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Schedule a Meeting
                                </button>
                            </div>
                        </div>
                    </form>

                    <img
                        src="assets/img/shapes/1.png"
                        class="contact-shape"
                        alt="shape"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
