import { Component } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  slideConfig = { slidesToShow: 1, slidesToScroll: 1 ,autoplay:true,
    
    arrows:true,};
    slider = [
      { img: 'assets/img/fslider1.webp' },
      { img: 'assets/img/fslider2.webp' },
      { img: 'assets/img/fslider3.webp' },
      { img: 'assets/img/fslider4.webp' },
      { img: 'assets/img/fslider6.webp' },
      { img: 'assets/img/fslider7.webp' }
      
    ];
    sliderConfig = { slidesToShow: 2, slidesToScroll: 1 ,autoplay:true,
      autoplaySpeed:1500,
      responsive: [
        {
        breakpoint: 767,
        settings:"unslick",
        },{
             breakpoint: 767,
             settings:{
                slidesToShow: 1,
                slidesToScroll: 1
             }
          }
       ]
    };
  

  slickInit(e: any) {
    
  }
  breakpoint(e: any) {
    
  }
  afterChange(e: any) {
    
  }
  beforeChange(e: any) {
    
  }
}
