<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>BUSINESS PROCESS
                                OUTSOURCING <br/>SERVICES</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Hire a BPO Resource</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
  
        








        
        
        
        
        
        
        
       

        


 
<div class="container">
    <div class="row">
        <div class="col-sm-6 contentOne">
             <h3>Streamline and automate your non-core processes with our BPO and digital transformation services.</h3>
<p>Hari Web Infotech provides front-office and back-office support services to ensure your business runs smoothly as you focus on the core aspects of your company.</p>
<ul>
<li><b>Specialized Teams</b></li>
<p>Hari Web Infotech's industry-specific support teams are comprised of dedicated experts, having decades of specialized experience. Our teams keep up with trends, regulations, and processes to stay ahead and produce optimal solutions.</p>

<li><b>All-Encompassing Support</b></li>
<p>We offer a blend of technical and non-technical resources, from customer service and human resource management to IT infrastructure support with managed services. Our experts and professionals will work to provide optimal solutions that fit your specific needs.</p>

<li><b>Consulting</b></li>
<p>Our expert advisors will help ensure a seamless process outsourcing experience with functional expertise and strategy. Through a combination of technology, data, and industry expertise, we will kick-start your BPO journey and work with you on your long-term path to greater efficiency and a competitive advantage in your industry.</p>

</ul>
        </div>
        <div class="col-sm-6">
            <img src="assets/img/solutions/anime.png" alt="">
        </div>
    </div>
</div>

<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Efficient Solutions Tailored for Success by Hari Web Infotech Pvt Ltd</span>
                        <h2>Streamline Operations and Focus on Growth with Business Process Outsourcing Services</h2>
                    </div>

                   
                   <p>
                    Welcome to a realm of enhanced operational efficiency and cost-effectiveness with Hari Web Infotech Pvt Ltd's Business Process Outsourcing (BPO) Services. Our BPO solutions are meticulously designed to empower businesses by outsourcing non-core functions, allowing you to focus on your core competencies and strategic initiatives.
                    <br/>
                    <br/>
At Hari Web Infotech, we understand the dynamic nature of modern business. Our BPO services encompass a wide array of functions, including customer support, data entry, back-office operations, finance and accounting, and more. By leveraging our expertise, you can optimize processes, reduce overheads, and access specialized skills without the hassle of in-house management.
<br/><br/>
Our experienced team collaborates seamlessly to ensure a smooth transition and consistent delivery of high-quality services. We tailor our BPO solutions to align with your business objectives, ensuring that your unique needs and requirements are met.
<br/><br/>
With a strong emphasis on data security and confidentiality, we employ the latest security measures to safeguard your sensitive information. Our goal is to provide you with a reliable outsourcing partnership that enhances your business operations and fuels growth.
<br/><br/>
Experience the transformative power of BPO with Hari Web Infotech Pvt Ltd. Contact us today to discover how our Business Process Outsourcing Services can elevate your business, allowing you to dedicate more time and resources to innovation and strategic endeavors.
                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








