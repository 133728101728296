import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-systems-integration',
  templateUrl: './systems-integration.component.html',
  styleUrls: ['./systems-integration.component.scss']
})
export class SystemsIntegrationComponent implements OnInit {

  constructor(private renderer: Renderer2, private title: Title, private meta: Meta) { }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Software integration services -- Enterprise Application Development");

    this.meta.updateTag({ name: 'description', content: "Leading software integration services for seamless connectivity. Unlock efficiency with our top-tier solutions. Your success, our priority." });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  }
}
