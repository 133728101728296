<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>AVIATION SOFTWARE
                                DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Experienced Developers from the Industry, Fostering Innovation.</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/WDD.webp" width="100%"/>
                             </div>
                        </div>
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/SD.webp" width="100%"/>
                             </div>
                        </div>
                        
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/DM.png" width="100%"/>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/SEOS.webp" width="100%"/>
                             </div>
                        </div>
                       
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/WDDD.webp" alt="theory image">
            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>Our Journey</span>
                        <h2>7+ Years of Industry Experience</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner"></i>
                                <p>Top Achiever</p>
                            </div>
                        </div>

                        

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake "></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake "></i>
                                <p>Quality Assured</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>
                    </div>
                   <p>
                    Established in 2015, Hari Web Infotech Pvt Ltd has been a pioneer in the IT industry, providing a wide range of cutting-edge services for over 7 years. Specializing in website designing and development, our team crafts visually stunning and user-friendly websites tailored to our clients' needs. We also excel in SEO services, ensuring enhanced online visibility and higher search engine rankings for our clients' businesses.

With a dedicated focus on complete digital marketing solutions, we have helped numerous businesses achieve remarkable growth in the digital realm. Our experienced team strategizes and implements result-driven marketing campaigns, leveraging various platforms and techniques to reach the right target audience and maximize ROI.
<br/><br/>

At Hari Web Infotech, we take pride in our commitment to delivering on-time, high-quality software solutions to businesses of all sizes. We understand the importance of technology in today's competitive landscape, and our custom software development services are designed to streamline operations, enhance productivity, and drive success.

Our customer-centric approach ensures that we listen to our clients' unique requirements and work collaboratively to turn their ideas into reality. With a strong emphasis on innovation and expertise, we consistently stay updated with the latest industry trends and technologies to deliver exceptional results.


                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








