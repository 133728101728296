<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Refund Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Refund Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="privacy-section pt-100">
    <div class="container">
        <h3>Scope and provision</h3>
        <p>This Refund Policy stated by Hariweb InfoTech covers its action, approach, and practices for refunds of the payment made by the clients. This Refund Policy does not cover the practices, approach, and action of any other person or organization or company that Hariweb InfoTech does not own or employ or manage including third party services/product providers, any third party website to/from Hariweb InfoTech’s website link.</p>
     
        <div class="privacy-text">
            <h3>Standard To Qualify The Refunds?</h3>   
            <p>When you submit your query or complaint or any issue to Hariweb InfoTech, first we will try to provide an ideal solution to your issues/complaints/queries. The technical team at Hariweb Infotech will take care of your case and then make a report on your issue/complaint/query and submit it to the panel of experts. Expert panel will take the final decision as to what to do. Refund will be made in the extreme case when no proper solution can be provided to solve your issues/queries/complaints.</p>          
            <p>We will check few circumstances and validity of the case before making any refund. Refund may be applicable if any of the below mentioned conditions (not limited to) will be satisfied:</p>  
                        
            <ul>
               <li>
                Development or post sales practices/approaches/operations has not been initiated</li>
                <li>You have made your payment and not violated any of the term related to payment</li>
                <li>Issues or complaints submitted by you or related to the projects are beyond the practice of rectification or resolution</li>
                    <li>Your claim is not based out of any billing dispute which will be issued from your payment vendor or bank</li>
                    <li> You have submitted all the proof and evidence to fulfill the refund policy and our agreement</li>
                    <li>You have not used or exchanged any information that may be accessed during the time of our association for your benefit</li>
                    <li>The reasons you have submitted are reasonable enough to qualify our refund policy</li>
            </ul>
        </div>
        <div class="privacy-text">
            <h3>How Will Refund Take Place?</h3>
            <p>Pre-Refund Process</p>
            <ul>
               <li>Your refund claim/query will be processed, analyzed, and verified</li>
               <li>The query/claim will be matched with the agreement parameters signed with the Hariweb InfoTech at the time of project initiation to validate the points/claims/queries</li>
               <li>If claim is viable, refund will be arranged with permission and settled fully or partly as per the validation of the case.</li>
            </ul>
        </div>
        <div class="privacy-text">
            <h3>Post-Refund Process</h3>
           <ul>
            <li>An agreement as a proof will be signed between the client and Hariweb Infotech</li>
            <li>Unless otherwise mentioned, you will destroy all the information communicated or shared with you over the period of time</li>
           </ul>
        </div>
        <div class="privacy-text">
            <h3>Transaction</h3>
            <ul>
                <li>The refund may take 10-12 business days after the processing of the refund</li>
                <li>You will be refunded in the same currency you have made payment to Hariweb InfoTech. In case, you request a change in currency, we will apply charges for exchange fees or a difference of the same amount will be made in the refund payment. It is completely your responsibility to bear any loss or to pay the fees applicable in this process.</li>
            </ul>
        </div>
        <div class="privacy-text">
            <h3>Changes</h3>
            <ul>
                <li>Hariweb InfoTech may amend this Refund Policy anytime, without any prior notice or intimation. You are expected to check the Refund Policy page at regular intervals for an updated policy. We are not liable for any misunderstanding or misconception related to the refund policy stated by us. For more information on our Refund Policy, you can contact us at mail.</li>
            </ul>
        </div>
    </div>
</div>