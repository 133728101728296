<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>REAL ESTATE SOFTWARE<br/>
                                DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
             </div>
        </div>
    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Explore Our Real Estate Software Solutions</h2>
                    <div class="row">
                        
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/af.webp" width="100%"/>
                                <p>Accounting & Finances</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/cERP.webp" width="100%"/>
                                <p>Real Estate ERP</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/ps.webp" width="100%"/>
                                <p>Payroll Software</p>
                             </div>
                        </div>
                        
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/aas.webp" width="100%"/>
                                <p>Asset Accounting Software</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/industries/bm.webp" width="100%"/>
                               <p>Property Management</p>
                            </div>
                        </div>
                       
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/jca.webp" width="100%"/>
                                <p>Job Cost Accounting</p>
                             </div>
                        </div>
                       
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/industry-pages/REAL ESTATE SOFTWARE.webp" class="w-100" alt="theory image">
            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Elevate Your Real Estate Business with Customized Software</span>
                        <h2>Transforming Real Estate with Innovative Software Solutions</h2>
                    </div>
                   <p>
                    Experience a revolution in the real estate industry with Hari Web Infotech Pvt Ltd's Real Estate Software Development Services. We specialize in creating advanced software solutions tailored to meet the dynamic needs of real estate professionals, agencies, and developers.
<br/><br/>
                    Our team of skilled developers, designers, and real estate experts collaborates seamlessly to craft cutting-edge software that simplifies complex processes, enhances efficiency, and empowers your business to thrive in today's competitive market. From property management systems and CRM platforms to real estate listing portals and virtual property tours, our solutions are designed to transform how you do business.
                    <br/><br/>          
                    With a deep understanding of the real estate landscape, we offer highly customizable software that seamlessly integrates with your workflows. Our user-friendly interfaces ensure smooth adoption and operation, enabling you to focus on what you do best - selling properties.
                    <br/><br/>
                    
                    
                    Experience the future of real estate with Hari Web Infotech Pvt Ltd. Contact us today to explore how our Real Estate Software Development Services can elevate your business, streamline operations, and provide a seamless experience to buyers, sellers, and agents.    
                </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








