// sitemap.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SitemapService {
  generateSitemap(): string {
    const baseUrl = 'https://hariwebinfotech.com';
    const routes = [
      '',
      'about',
      'service',
      'service-details',
      'portfolio-two',
      'portfolio',
      'pricing',
      'error',
      'faq',
      'team',
      'team-two',
      'privacy',
      'shipping',
      'refund-policy',
      'terms-policy',
      'blog',
      'blog-two',
      'blog-three',
      'blog-details',
      'contact',
      'web-design',
      'softwere-development',
      'graphic-desigining',
      'app-development',
      'web-development',
      'elementor-17945',
      'ppc-2',
    ]; // Add more routes as needed

    return `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${routes.map(
          (route) => `
            <url>
              <loc>${baseUrl}/${route}</loc>
            </url>
          `
        ).join('')}
      </urlset>`;
  }
}
