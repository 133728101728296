<app-navbar-one></app-navbar-one>

<div class="privacy-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Terms & Policy</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Terms & Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="privacy-section pt-100">
    <div class="container">
        <h3>Terms of Use</h3>
        <p>Hariweb InfoTech Copyrights</p>
        <p>All information, data, and content that include (not limited to) text, images, graphics, videos, logos, and any software programs available on/through this website are protected by privacy law, copyrights, and other laws. You are not allowed to copy, modify, distribute, publish, transmit, sell, license, creation of derivative works, and making any use of content available on/through this website for any public, private, as well as commercial use.</p>
        <p>Hariweb Infotech cannot be held responsible for web hosting, custom artwork, graphics related work or data entry unless and until it has been specifically mentioned, agreed and paid for by both the parties involved in the contract. Any image, artwork or text written or designed by Hariweb for clients, will be owned by Hariweb unless there has been any other agreement made between them.</p>
        <div class="privacy-text">
            <h3>Payments</h3>   
            <p>Hariweb accepts payment by Cash, credit card, debit card, cheque, digital wallet, or bank transfer. We hold the right to decline the acceptance of payment through any of these means without any intimation or notice. Without any limitation and prior notice, we can make changes in price and we can withdraw any payment methods.</p>
        </div>
        <div class="privacy-text">
            <h3>Web Servers:</h3>
           <p>We may apply a cancellation fee if the customer cancels the service before its completion. The cancellation fee may be of any amount and it could be equal to the amount of work completed at the point of cancellation. In the event, a customer is not able to or willing to pay the cancellation fee or any overdue amount will result in legal action by us if required.</p>
        </div>
        <div class="privacy-text">
            <h3>Jurisdiction</h3>
           <p>The terms “Jurisdiction” will be governed by and interpreted and applied in accordance with Indian Law. It must be understood and automatically agreed upon by parties involved that the exclusive jurisdiction to settle any dispute that might arise related to Terms and Conditions reside with the courts in India. Any order for service placed with Hariweb will automatically confirm the acceptance of these terms and conditions which are mentioned above.</p>
        </div>
        <div class="privacy-text">
            <h3>Timescale</h3>
            <p>Hariweb InfoTech will be committed to complete all services within the agreed timeline or timescale as per the project requirement. The timescale will commence only if both the parties agree to it prior to contract finalization. Hariweb InfoTech may require to extend any timescale, sometime, in any adverse condition beyond its control.</p>
        </div>
        <div class="privacy-text">
            <h3>Severability</h3>
            <p>In case if any one or more conditions mentioned in the Agreement and/or Order is found illegal, invalid or unenforceable, the rest of the clauses of the Agreement and/or Order does not become void and the Agreement and/or Order cannot be cancelled for this reason alone. The illegal, invalid or unenforceable clause can be replaced by another legal, valid and enforceable clause upon mutual acceptance by both parties.</p>
        </div>
        <div class="prvacy-text">
            <h3>Limitations of Liability</h3>
            <p>In case if any one or more conditions mentioned in the Agreement and/or Order is found illegal, invalid or unenforceable, the rest of the clauses of the Agreement and/or Order does not become void and the Agreement and/or Order cannot be cancelled for this reason alone. The illegal, invalid or unenforceable clause can be replaced by another legal, valid and enforceable clause upon mutual acceptance by both parties.</p>
            <p>Hariweb InfoTech will use reasonable and optimal skill set and resources to complete the project or to deliver the service. Also, Hariweb InfoTech makes no representation and excludes any warranty/guarantee, implied or expressed, as to the accuracy, performance, completeness, availability of the service.</p>
            <p>We (including Company, employees, agents, and associative) exclude ourselves from any/all liability for damage/loss as a result of any delay, error, inaccuracy, omission, or any reason of negligence or any other reasons in the production of products or delivery of the project.</p>
            <p>Under no circumstances shall we be held responsible for any direct, indirect or consequential damages including decline in profit, damage or loss to property or claims from third party in the contract.</p>
            <p>We are not liable for the service provided by any third party organization associated with us in any manner. We must not be held liable for the failure of any third party in providing any services.</p>
        </div>
        <div class="privacy-text">
            <h3>Approvals and Delivery of the projects</h3>
            <p>One single project could be embraced with various stages or steps and the work for the next stage will only be initiated after the making of relevant payment as per the agreement.</p>
            <p>All code and other material or content developed by Hariweb InfoTech will be transferred after the completion of the projects and proper sign offs. The ownership of the code developed by Hariweb Infotech will be handed over to the client after the final payment.</p>
            <p>On completion of the service, the product will be uploaded to the server of the client or integrated with the client’s system, as preferred by them and according to the agreement. Hariweb InfoTech hold the right to hold or delay the integration or uploading or delivery of the final product until the full and final payment has been received.</p>
            <p>Hariweb InfoTech holds the right to use or publish the completed work or product for reference to the other potential clients. In the event, any client wants to withhold this right, the client has to notify Hariweb Infotech in advance and prior to the finalization of the agreement of the project with the complete agreement of the both parties (client and Hariweb InfoTech).</p>
        </div>
        <div class="privacy-text">
            <h3>Privacy</h3>
            <p>Hariweb InfoTech collects data and information submitted by visitors or users to the website so that we can respond to their request or contact them. We abide by some privacy policy, you can check it out at <span>Privacy policy.</span></p>
        </div>
    </div>
</div>