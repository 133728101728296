<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>SUPPLY CHAIN MANAGEMENT
                                SOFTWARE DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Supply Chain Management (SCM) Software Development Services</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/mrpscs.webp" width="100%"/>
                                <p>MRP Supply Chain Solutions</p>
                             </div>
                        </div>
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/erpsi.webp" width="100%"/>
                                <p>ERP & MRP System Integration</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/industries/rscms.webp" width="100%"/>
                               <p>Regulatory SCM Compliance Software</p>
                            </div>
                        </div>
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/ada.webp" width="100%"/>
                                <p>Advanced Analytics</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/rttscm.webp" width="100%"/>
                                <p>Real-Time Tracking</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/erpi.webp" width="100%"/>
                                <p>ERP Integration</p>
                             </div>
                        </div>
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/industry-pages/supply.webp" class="w-100" alt="theory image">
            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Optimize Operations with Supply Chain Management Software</span>
                        <h2>Streamline Your Supply Chain with Custom Software Solutions</h2>
                    </div>

                  
                   <p>
                    Unlock the potential of a seamless and efficient supply chain with Hari Web Infotech Pvt Ltd's Supply Chain Management Software Development Services. We are committed to revolutionizing your supply chain operations through innovative software solutions that optimize processes, reduce costs, and enhance visibility.
<br/><br/>
                    Our team of experienced developers and supply chain experts collaborates seamlessly to create tailor-made software solutions that address the complexities of modern supply chains. From inventory management and order processing to logistics coordination and demand forecasting, our solutions are designed to empower your business to thrive in a competitive market.
                    <br/><br/>          
                    With a deep understanding of the intricacies of supply chain management, we deliver highly customizable software that integrates seamlessly into your existing workflows. Our user-friendly interfaces ensure quick adoption and efficient use by your team.
                    <br/><br/>
                    
                    
                    Transform your supply chain into a strategic asset with Hari Web Infotech Pvt Ltd. Contact us today to explore how our Supply Chain Management Software Development Services can help you achieve operational excellence, minimize risks, and drive growth in your industry.


                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <p class="mb-0">The Time has changed</p>
                    <h2>Why Choose Us?</h2>
                    
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        To become the most preferred brand offering transformative digital marketing, HR and IT solutions globally where client’s imaginations are turned into reality.     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in consistency and continual improvement as it determines our customer satisfaction that gives us sustainable competitive advantage and customers' confidence in the highly competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Timeline: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our commitment to the timeline for each project stems from the belief that every business has a strategy for its growth and our strict adherence to delivery timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We are aligned on our intentions and actions realizing the importance of the data and information we handle for our client. We are committed to maintain absolute confidentiality of our client’s business secrets not only during the period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








