<app-navbar-one></app-navbar-one>
<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Website Designing</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Website Designing</li>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="service-details">
    <div class="container mt-5">
        <div class="row d-flex mt-5">
            <div class="col-md-6">
                <h2>Your Vision, Our Mission</h2>
                <p>
                    At Hari Web Infotech, we understand the importance of
                    individuality. Our
                    <a href="/web-design"><b>web designing</b></a> services are
                    dedicated to creating websites that mirror your unique
                    identity. Whether you're a startup or an established brand,
                    we tailor each project to embody your values and goals. Our
                    expert team collaborates closely with you to bring your
                    vision to life, resulting in a website that stands out and
                    resonates with your audience. Trust us to transform your
                    ideas into a digital reality that truly represents who you
                    are.
                </p>

                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/img/Website-Designing-1.webp" alt="" />
            </div>
        </div>
        <div class="row d-flex mt-5">
            <div class="col-md-6">
                <h2>Beyond Design, Building Relationships</h2>
                <p>
                    Step into the digital world with our exceptional <a href="/web-design"><b>web designing services in Noida,Delhi</b></a>. Let us weave magic on your digital
                    canvas, empowering your business to stand out in the vast
                    online landscape. Embrace the cool creativity of Hari Web
                    Infotech Pvt Ltd and embark on a <a href="/web-design"><b>web designing</b></a> journey like
                    never before. Reach out to us today, and together, we'll
                    sculpt a digital masterpiece that truly reflects your
                    brand's essence. Let's redefine your online presence with
                    innovation and style!
                </p>

                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/img/beyond-design.jpeg" alt="" />
            </div>
        </div>
        <div class="row d-flex mt-5">
            <div class="col-md-6">
                <img src="assets/img/user-experience.webp" alt="" />
            </div>
            <div class="col-md-6">
                <h2>Seamless User Experience, Unforgettable Journey</h2>
                <p>
                    At Hari Web Infotech, audience-centric design is our mantra.
                    Our websites are meticulously crafted to provide a seamless
                    and intuitive user experience. From effortless navigation to
                    visually engaging elements, we ensure your visitors have an
                    unforgettable journey. We believe that a well-designed
                    website is more than just aesthetics; it's about captivating
                    your audience and keeping them spellbound. Trust us to
                    create a digital masterpiece that leaves a lasting
                    impression and drives your online success. Let's design your
                    website with your audience in mind today!
                </p>

                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
        <div class="row d-flex mt-5">
            <div class="col-md-6">
                <h2>Customer Satisfaction, Our Top Priority</h2>
                <p>
                    At Hari Web Infotech, your satisfaction fuels our passion
                    for excellence. We take immense pride in delivering
                    top-notch quality with meticulous attention to detail. Our
                    customer-centric approach ensures that your  <a href="/web-development"><b>website</b></a> is not
                    just exceptional but exceeds your expectations. From
                    conceptualization to execution, we strive for perfection,
                    tailoring every aspect to suit your unique requirements.
                    Trust us to create a website that reflects your vision and
                    drives your success. Your satisfaction is our ultimate goal!
                </p>

                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/img/custmor-saticfaction.jpeg" alt="" />
            </div>
        </div>
        <div class="row d-flex mt-5">
            <div class="col-md-6">
                <img src="assets/img/unleash-creativity.jpeg" alt="" />
            </div>
            <div class="col-md-6">
                <h2>Unleash Creativity, Embrace Innovation</h2>
                <p>
                    At Hari Web Infotech, innovation is our driving force. We
                    are passionate about creating designs that transcend the
                    ordinary. Our visually stunning creations are enriched with
                    the latest trends and user-centric features, ensuring your
                    audience is captivated and compelled to return for more. We
                    blend creativity with cutting-edge technology to craft
                    websites that leave a lasting impact, setting you apart in
                    the competitive digital landscape. Embrace innovation with
                    us and elevate your online presence to new heights!
                </p>

                <div class="theme-button">
                    <a href="tel:+1-442-259-2040" class="default-btn me-4 p-2"
                        >Call Expert</a
                    >
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        class="default-btn me-4 p-2"
                        >Schedule a Demo</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>

<div class="service-section mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 service-img"></div>

            <div class="col-md-7 offset-md-5 p-0">
                <div class="service-text">
                    <p>Best</p>
                    <h2 class="mb-0">
                        This things Makes us Best Static <a href="/web-design"><b>Website designing</b></a> Company
                        In Delhi
                    </h2>
                    <p class="mt-0">
                        Needless to say that Web designing is a popular aspect
                        of online marketing and business. In this field, the
                        trend is consistently changing with time, so we need to
                        watch those changes and the latest trends to serve the
                        best—Static <a href="/web-design"><b>Website Design</b></a> Company In Delhi, India.
                    </p>

                    <div class="theme-button">
                        <a
                            href="tel:+1-442-259-2040"
                            class="default-btn me-4 p-2"
                            >Call Expert</a
                        >
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            class="default-btn me-4 p-2"
                            >Schedule a Meeting</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img
                            src="assets/img/Website-Designing-3.webp"
                            alt="service details"
                        />
                    </div>

                    <!------------------
                       <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/img/service-details-2.jpg" class="service-btm-img" alt="service details">
                        </div>

                        <div class="col-lg-6">
                            <img src="assets/img/service-details-3.jpg" class="service-btm-img" alt="service details">
                        </div>
                    </div>

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    ----------------------->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="service-category">
                    <h3>Services</h3>

                    <ul>
                        <li>
                            <a routerLink="/web-design"
                                >WEBSITE DESIGNING <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/app-development"
                                >APP DEVELOPMENT <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/softwere-development"
                                >SOFTWARE DEVELOPMENT
                                <i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/graphic-desigining"
                                >GRAPHICS DESIGNING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                        <li>
                            <a routerLink="/digital-marketing"
                                >DIGITAL MARKETING<i class="icofont-arrow"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="contact-section pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 Website-Designing-3">
                <img src="assets/img/Website-Designing-4.webp" alt="" />
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>

                    <form id="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        placeholder="Name"
                                        [(ngModel)]="formData.name"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        placeholder="Email"
                                        [(ngModel)]="formData.email"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        placeholder="Subject"
                                        [(ngModel)]="formData.subject"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        name="message"
                                        class="message-field"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        placeholder="Message"
                                        [(ngModel)]="formData.message"
                                        required
                                        [ngModelOptions]="{ standalone: true }"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Send Message
                                </button>

                                <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    class="default-btn contact-btn me-4 p-2"
                                >
                                    Schedule a Meeting
                                </button>
                            </div>
                        </div>
                    </form>

                    <img
                        src="assets/img/shapes/1.png"
                        class="contact-shape"
                        alt="shape"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
