<app-navbar-one></app-navbar-one>

<div class="main-banner" id="home-bnner">
    <div class="container">
       <div class="row">
           <div class="col-sm-1"></div>
           <div class="col-sm-10">
               <div class="banner-text">
                   <h1>ABOUT <br/>US</h1>
                   <div class="theme-button">
                       <a routerLink="/contact"  class="default-btn p-2">Know More</a>
                   </div>
               </div>
               <app-logoslider></app-logoslider>
           </div>
           <div class="col-sm-1"></div>
       </div>
     
    
   </div>
</div>
<section class="about-section pb-0"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>Our Proud</span>
                        <h2>7+ Years of Experience</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>
                        At Hari Web Infotech Pvt Ltd, we are not just an IT company; we are your dedicated partner in harnessing the power of technology to elevate your business to new heights. With a commitment to innovation, excellence, and client satisfaction, we have established ourselves as a leading player in the realm of software development, web development, digital marketing, custom CRM development, and a plethora of other IT services.
<br/><br/>
<b>Our Vision</b><br/>
To be at the forefront of technological advancements, empowering businesses of all sizes to thrive in the digital age. We envision a world where businesses can leverage cutting-edge solutions to streamline operations, enhance customer experiences, and achieve sustainable growth.
<br/><br/>
<b>Our Mission</b><br/>
Our mission is to deliver exceptional IT solutions that cater to the unique needs of our clients. By blending creativity, technical expertise, and strategic thinking, we strive to create value-driven software, websites, and digital campaigns that make a tangible impact on business success.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</section>


 

 



<section class="about-section pb-100 pt-0">
    <div class="progress-section mt-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7 progress-image"></div>
    
                <div class="col-md-6 offset-md-6 p-0">
                    <div class="progress-text">
                       
                        <h2>Why Choose Us?</h2>
                        
                        <div class="why-choose-accordian">
                            <div class="accordion" id="accordionExample">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                1.Expertise:
                                            </a>
                                        </h2>
                                    </div>                           
                                    <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            Our team comprises seasoned professionals who are experts in their respective fields. Whether it's software development, web design, digital marketing, or CRM development, we have the right talent to deliver outstanding results.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0">
                                            <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                2. Innovation:
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            We stay ahead of the curve by embracing the latest technologies and trends. Our innovative approach ensures that your business benefits from the most cutting-edge solutions available.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                3. Tailored Solutions: 
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            We understand that every business is unique. That's why we work closely with our clients to develop customized solutions that align with their goals and requirements.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0">
                                            <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                4. Client-Centric Approach:
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            Your success is our priority. We are committed to building strong, long-lasting relationships with our clients. Your satisfaction is our measure of success.
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h2 class="mb-0">
                                            <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                4. Quality Assurance:
                                            </a>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div class="card-body">
                                            We have a rigorous quality assurance process in place to ensure that our deliverables meet the highest standards. This commitment to quality guarantees that you receive solutions that perform seamlessly and reliably.
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-slider></app-slider>
