import { HttpClient } from '@angular/common/http';
import { Component, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-web-design',
  templateUrl: './web-design.component.html',
  styleUrls: ['./web-design.component.scss']
})
export class WebDesignComponent {
  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'
  }

  constructor(private http: HttpClient,private renderer: Renderer2,private title: Title,private meta: Meta) {

  }
  successNotification() {
    Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
  }
  ngOnInit(): void {
    this.setCanonicalURL(window.location.href);

    this.title.setTitle("Website Designing Company In Delhi- Hari Web Infotech");

    this.meta.updateTag({ name: 'description', content: "Hari Web Infotech, a best website designing company in Delhi. Expertise in responsive design, SEO-friendly websites design services , and digital solutions. Elevate your brand with our creative web design solutions!" });
  }

  private setCanonicalURL(url: string) {
    // Remove existing canonical tag if any
    const existingCanonical = this.renderer.selectRootElement('link[rel="canonical"]');
    if (existingCanonical) {
      this.renderer.removeChild(document.head, existingCanonical);
    }

    // Create and append new canonical tag
    const canonicalTag = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalTag, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalTag, 'href', url);
    this.renderer.appendChild(document.head, canonicalTag);
  } 
  resetForm() {
    this.formData =
    {
      name: '',
      phone: '',
      message: '',
      serviceType: '',
      subject: '',
      email: '',
      mailto: 'support@hariwebinfotech.com'
    }
  }
  onSubmit() {
    const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
    if (this.formData.email === '') {
      this.formData.email = "N/A";
    }
    if (this.formData.phone === '') {
      this.formData.phone = 'N/A';
    }
    if (this.formData.subject === '') {
      this.formData.subject = 'N/A';
    }
    if (this.formData.serviceType === '') {
      this.formData.serviceType = 'N/A';
    }
    this.http.post(apiUrl, this.formData).subscribe(
      (response) => {
        // alert("Message Sent Successfully! Our Team Will Contact You Shortly");

        this.successNotification();

        console.log('emailsend');

        this.resetForm();
      }, (error) => {
        console.log('error', error);
      }
    )
  }

}
