<section id="sss2" class="Latest-Works mt-4">
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="sliderConfig" 
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of slider" class="Latest">
    <img style="border-radius: 20px;" src="{{ slide.img }}" alt="" width="100%">
    </div>
    </ngx-slick-carousel>
</section>