<app-navbar-one></app-navbar-one>

<div class="main-banner" id="home-bnner">
    <div class="container">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
                <div class="banner-text">
                    <h1>
                        DIGITAL MARKETING<br />
                        SERVICES
                    </h1>
                    <div class="theme-button">
                        <a routerLink="/contact" class="default-btn p-2"
                            >Get Developers Now</a
                        >
                    </div>
                </div>
                <app-logoslider></app-logoslider>
            </div>
            <div class="col-sm-1"></div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-sm-6 contentOne">
            <h3>Drive More Revenue with our Digital Marketing Experts</h3>

            <p>
                Whether you require digital marketing experts-for-hire to
                augment your existing in-house experts team, or a newly
                developed
                <a href="/digital-marketing"
                    ><b>digital marketing solutions in Noida,Delhi</b></a
                >
                to help your business drive more revenue, Hari Web Infotech Pvt
                Ltd’s got you covered.
            </p>
            <ul>
                <li><b>Marketing Automation</b></li>
                <p>
                    We leverage our developers’ deep working knowledge of
                    artificial intelligence, business intelligence, and machine
                    learning to create unique marketing automation platforms,
                    constructed as centralized databases that streamline
                    workflows.
                </p>
                <li><b>Consumer-Centric Analytics</b></li>
                <p>
                    We build analytics & reporting dashboards that integrate
                    with your existing enterprise apps and your consolidated
                    siloed data to create easily shareable KPI visualizations,
                    giving you a holistic view of your marketing campaign
                    success metrics.
                </p>
                <li><b>Chatbot Software Development</b></li>
                <p>
                    Our engineers develop and integrate custom chatbot software
                    with personalized interactive voice response (IVR)
                    technology to simplify the buying process and offer the
                    ability to monitor the consumer journey to improve customer
                    relationships.
                </p>
                <li><b>Third-Party Integrations</b></li>
                <p>
                    We integrate all of your systems together to create a
                    unified, all-in-one digital marketing software solution,
                    including your social media, SEO, DAM, email marketing
                    campaigns, lead management, data management, and analytic
                    dashboards.
                </p>
            </ul>
        </div>
        <div class="col-sm-6">
            <br />
            <br />
            <br />
            <img src="assets/img/solutions/cloud.png" alt="" />
        </div>
    </div>
</div>

<section class="about-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span
                            >Harness the Power of Hari Web Infotech Pvt Ltd's
                            Strategic Solutions for Digital Success</span
                        >
                        <h2>
                            Amplify Your Brand's Online Presence with Expert
                            Digital Marketing Services
                        </h2>
                    </div>

                    <p>
                        Welcome to the forefront of digital excellence with Hari
                        Web Infotech Pvt Ltd's comprehensive <a href="/digital-marketing"><b>Digital Marketing
                            Services in Noida,Delhi </b></a>. Our team of skilled professionals is dedicated
                        to driving your brand's online success through strategic
                        and innovative digital marketing solutions.
                        <br /><br />
                        With a deep understanding of the dynamic digital
                        landscape, we offer a range of services designed to
                        enhance your online visibility, engage your target
                        audience, and maximize conversions. From search engine
                        optimization (SEO) and pay-per-click (PPC) advertising
                        to social media management and content marketing, our
                        holistic approach ensures a cohesive and effective
                        online presence.
                        <br /><br />
                        At Hari Web Infotech, we believe in data-driven
                        strategies. Our experts analyze trends, user behavior,
                        and market insights to tailor campaigns that resonate
                        with your audience. We understand that each business is
                        unique, and our customized strategies are designed to
                        align with your specific goals.
                        <br /><br />
                        Our commitment to excellence extends to transparent
                        reporting and regular performance analysis. We keep you
                        informed about the progress of your campaigns and make
                        data-driven adjustments to ensure optimal results.
                        <br /><br />
                        Experience the power of digital transformation with Hari
                        Web Infotech Pvt Ltd. Contact us today to explore how
                        our Digital Marketing Services can elevate your brand,
                        expand your reach, and drive your business towards
                        unrivaled online success.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<br />
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <h2>Why Choose Us?</h2>

                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1.Passion:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseOne"
                                    class="collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        To become the most preferred brand
                                        offering transformative digital
                                        marketing, HR and IT solutions globally
                                        where client’s imaginations are turned
                                        into reality.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2. Quality:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseTwo"
                                    class="collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        We believe in consistency and continual
                                        improvement as it determines our
                                        customer satisfaction that gives us
                                        sustainable competitive advantage and
                                        customers' confidence in the highly
                                        competitive marketplace.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3. Timeline:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseThree"
                                    class="collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        Our commitment to the timeline for each
                                        project stems from the belief that every
                                        business has a strategy for its growth
                                        and our strict adherence to delivery
                                        timeline will improve ROI for clients.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a
                                            href="#"
                                            class="btn collapsed"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4. Integrity:
                                        </a>
                                    </h2>
                                </div>
                                <div
                                    id="collapseFour"
                                    class="collapse"
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div class="card-body">
                                        We are aligned on our intentions and
                                        actions realizing the importance of the
                                        data and information we handle for our
                                        client. We are committed to maintain
                                        absolute confidentiality of our client’s
                                        business secrets not only during the
                                        period of our contract but beyond.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>
