<app-navbar-two></app-navbar-two>

<div class="pricing-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Pricing</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Pricing</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>ALMOST FREE</h2>
                        <h3><span>$99 -</span> <span> $399</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>WEBSITE STTARTER</h2>
                        <h3><span>$399 -</span> <span> $999</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>BUSINESS BULDERS</h2>   
                        <h3><span>$999 -</span> <span> $1499</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                  <!-----------
                          <ul>
                            <li><i class='bx bx-check'></i> Up to 10 chat operators</li>
                            <li><i class='bx bx-check'></i> 500 ChatBot Triggers</li>
                            <li><i class='bx bx-check'></i> Quick Responses</li>
                            <li><i class='bx bx-check'></i> Google Analytics</li>
                            <li><i class='bx bx-check'></i> Messenger Integration</li>
                            <li><i class='bx bx-check'></i> Email Integration</li>
                            <li><i class='bx bx-check'></i> Mobile + Desktop Apps</li>
                            <li><i class='bx bx-check'></i> Drag & Drop Widgets</li>
                        </ul>
                    ----------->
                    </div>
                    

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
</div>
<div class="pricing-section pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>PROFESSIONAL WEBSITE</h2>
                        <h3><span>$1499 -</span> <span> $1999</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>BUSINESS BOOSTER WEBSITE</h2>
                        <h3><span>$1999 -</span> <span> $4999</span></h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                    </div>

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>CUSTOM WEBSITE</h2>
                        <h3>Get Price</h3>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i>Domain Name</li>
                            <li><i class='bx bx-check'></i>1-3 Pages</li>
                            <li><i class='bx bx-check'></i> Web Hosting </li>
                            <li><i class='bx bx-check'></i> Business Email Adress</li>
                            <li><i class='bx bx-check'></i> Logo Design (2 Samples)</li>
                            <li><i class='bx bx-check'></i> Image (4 Stock Images)</li>
                            <li><i class='bx bx-check'></i> WIX, WordPress</li>
                            <li><i class='bx bx-check'></i> ECommerce</li>
                            <li><i class='bx bx-check'></i>Mobile Responses Web Design</li>
                            <li><i class='bx bx-check'></i>Website Content Writer</li>
                            <li><i class='bx bx-check'></i>Fully SEO Optimization</li>
                            <li><i class='bx bx-check'></i>User-Friendly Navigation</li>
                            <li><i class='bx bx-check'></i>Graphic Works (Costum Graphic & Animation)</li>
                            <li><i class='bx bx-check'></i>No SSL Included</li>
                            <li><i class='bx bx-check'></i>Delivery Time</li>
                            <li><i class='bx bx-check'></i>Good For Small Business</li>
                        </ul>
                  <!-----------
                          <ul>
                            <li><i class='bx bx-check'></i> Up to 10 chat operators</li>
                            <li><i class='bx bx-check'></i> 500 ChatBot Triggers</li>
                            <li><i class='bx bx-check'></i> Quick Responses</li>
                            <li><i class='bx bx-check'></i> Google Analytics</li>
                            <li><i class='bx bx-check'></i> Messenger Integration</li>
                            <li><i class='bx bx-check'></i> Email Integration</li>
                            <li><i class='bx bx-check'></i> Mobile + Desktop Apps</li>
                            <li><i class='bx bx-check'></i> Drag & Drop Widgets</li>
                        </ul>
                    ----------->
                    </div>
                    

                    <div class="theme-button text-center">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>