<app-navbar-one></app-navbar-one>

<div class="container pt-50 pb-100">
    <div class="row mainRow">
        <h2>Contact Us</h2>
        <p>Fill out our form and a software expert will contact you within 24hrs.</p><br />

        <div class="col-sm-8">
            <form id="contactForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="exampleFormControlInput1" class="form-label">First Name*</label>
                        <input type="text" class="form-control" required="required" [(ngModel)]="formData.name" required
                            [ngModelOptions]="{standalone: true}" id="exampleFormControlInput1" placeholder="">
                    </div>
                    <div class="col-sm-6">
                        <label for="exampleFormControlInput1" class="form-label">Last Name*</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="">
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-6">
                        <label for="exampleFormControlInput1" class="form-label">Phone*</label>
                        <input type="number" class="form-control" required="required" id="exampleFormControlInput1"
                            [(ngModel)]="formData.phone" required [ngModelOptions]="{standalone: true}" placeholder="">
                    </div>
                    <div class="col-sm-6">
                        <label for="exampleFormControlInput1" class="form-label">Company (Optional)</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1"
                            [(ngModel)]="formData.subject" required [ngModelOptions]="{standalone: true}"
                            placeholder="">
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-12">
                        <label for="exampleFormControlTextarea1" class="form-label">How Can We Help You ?*</label>
                        <textarea class="form-control" required="required" id="exampleFormControlTextarea1"
                            [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}"
                            rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <button type="submit" class="myBtn" [disabled]="isLoading">Speak With A Software Expert
                        </button>

                    </div>
                    <div class="col-sm-1">
                        <span *ngIf="isLoading">
                            <div class="spinner-grow sp" role="status">

                            </div>
                        </span>
                    </div>
                </div>

            </form>
        </div>
        <div class="col-sm-4 mainRow">
            <div class="row cont">
                <div class="col-sm-12">

                    <img src="assets/img/indiaflag.svg" height="30px" width="25px" alt="Flag of India" /> Phone:
                    &nbsp;+91 9990335957
                </div>
            </div>
            <div class="row cont">
                <div class="col-sm-12">

                    <img src="assets/img/usflag.svg" height="30px" width="25px" alt="Flag of India">
                    Phone:&nbsp;+1-442-259-2040
                </div>
            </div>
            <div class="row cont">
                <div class="col-sm-12">
                    <img width="25px" height="25px" src="https://img.icons8.com/ios/50/new-post--v1.png"
                        alt="new-post--v1" />&nbsp;support@hariwebinfotech.com

                </div>
            </div>
            <div class="row">
                <h3>Locations</h3>
                <div class="col-sm-12" style="padding: 0 17px 0 17px;color: black;">
                    <b>Operational Office <img src="assets/img/indiaflag.svg" height="30px" width="15px"
                            alt="Flag of India">&nbsp;</b><br />Plot No – 15 Second Floor, Palam Extension, Sec 7 Dwarka
                    New Delhi 110075<br /><br />
                    <b>US Based Office <img src="assets/img/usflag.svg" height="30px" width="15px"
                            alt="Flag of India">&nbsp;</b><br />603 Seagaze Drive 779 Oceanside, CA 92054 United States


                </div>
            </div>
        </div>
    </div>
</div>