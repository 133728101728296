<style>
    .footer-area {
        background-color: rgba(0, 0, 0, 0.7);
        background-image: url("/assets/img/foot.webp");
        background-size: cover;
        background-position: center;
        color: white;
    }
</style>
<div class="newsletter-section">
    <div class="container">
        <div class="newsletter-area">
            <h2>Unleashing Digital Excellence</h2>

            <div class="col-lg-6 offset-lg-3 p-0">
                <p>
                    Empowering Your Vision with Cutting-Edge Solutions, Unleash
                    the Potential of Innovation with Hari Web Infotech Pvt Ltd
                </p>
            </div>
            <img
                src="assets/img/shapes/2.png"
                class="subscribe-shape"
                alt="shape"
            />
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"
                            ><img
                                src="assets/img/hwiwhite.png"
                                alt="logo"
                                class="w-50"
                        /></a>
                    </div>
                    <p>
                        Now you can easily connect with us . Just click on given
                        social media links and talk with our executive.
                    </p>
                    <div class="footer-social">
                        <ul class="d-flex">
                            <li>
                                <a
                                    href="https://www.facebook.com/hariwebinfotechpvtltd"
                                    target="_blank"
                                    ><i class="flaticon-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/hari_web"
                                    target="_blank"
                                    ><i class="flaticon-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://in.linkedin.com/company/hari-web-infotech"
                                    target="_blank"
                                    ><i class="flaticon-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/hwi_097/"
                                    target="_blank"
                                    ><i class="flaticon-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <ul>
                        <li>
                            <a href="tel:+14422592040"
                                ><img
                                    src="assets/img/usflag.svg"
                                    height="30px"
                                    width="22px"
                                    alt="Flag of India"
                                />&nbsp; +1 442-259-2040</a
                            >
                        </li>
                        <li>
                            <a href="tel:+919990335957"
                                ><img
                                    src="assets/img/indiaflag.svg"
                                    height="30px"
                                    width="22px"
                                    alt="Flag of India"
                                />&nbsp; +91 999-033-5957</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Services</h3>

                    <ul>
                        <li class="nav-item">
                            <a
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                routerLink="/api-developement"
                                >API Development</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                routerLink="/web-developement"
                                >Web Development</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                routerLink="/application-maintainence"
                                >Application Maintenance</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                routerLink="/business-process"
                                >Business Process Outsourcing</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                routerLink="/custom-softwares"
                                >Custom Software Services</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li>
                            <a routerLink="/"
                                ><i class="flaticon-right"></i> Home</a
                            >
                        </li>
                        <li>
                            <a routerLink="/about"
                                ><i class="flaticon-right"></i> About Us</a
                            >
                        </li>
                        <!-- <li><a routerLink="/blog"><i class="flaticon-right"></i> Blog</a></li> -->
                        <li>
                            <a routerLink="/refund-policy"
                                ><i class="flaticon-right"></i> Refund &
                                Policy</a
                            >
                        </li>
                        <li>
                            <a routerLink="/contact"
                                ><i class="flaticon-right"></i> Contact</a
                            >
                        </li>
                        <li>
                            <a routerLink="/contact"
                                ><i class="flaticon-right"></i> Careers</a
                            >
                        </li>
                        <li>
                            <a routerLink="/shipping"
                                ><i class="flaticon-right"></i> Shipping &
                                Delivery
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>

                    <ul>
                        <li>
                            <b
                                >Operational Office
                                <img
                                    src="assets/img/indiaflag.svg"
                                    height="30px"
                                    width="15px"
                                    alt="Flag of India"
                                />&nbsp;</b
                            ><br /><br />Plot No – 15 Second Floor, Palam
                            Extension, Sec 7 Dwarka New Delhi 110075
                        </li>
                        <li>
                            <b
                                >US Based Office
                                <img
                                    src="assets/img/usflag.svg"
                                    height="30px"
                                    width="15px"
                                    alt="Flag of India"
                                />&nbsp;</b
                            ><br /><br />603 Seagaze Drive 779 Oceanside, CA
                            92054 United States
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 lh-1 text-start">
                    <p>©2023 Hari Web Infotech Pvt Ltd. All Rights Reserved.</p>
                </div>

                <div class="col-md-6 lh-1 text-end">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li>
                            <a routerLink="/terms-policy">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="right-modal">
    <div
        class="modal fade right"
        id="sidebar-right"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <a routerLink="/"
                        ><img src="assets/img/Hariwebinfotech3.webp" alt="logo"
                    /></a>
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 p-0">
                                <div class="row">
                                    <div class="col">
                                        <ul class="list-style-none">
                                            <li>
                                                <i class="flaticon-right"></i
                                                ><a href="#">Home</a>
                                            </li>
                                            <li><a href="#">About</a></li>
                                            <li><a href="#">Services</a></li>
                                            <li><a href="#">Portfolio</a></li>
                                            <li><a href="#">Pricing</a></li>
                                            <li><a href="#">Company</a></li>
                                            <li><a href="#">Contact</a></li>
                                            <li><a href="#">Home</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
