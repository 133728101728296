<app-navbar-one></app-navbar-one>



<div class="main-banner" id="home-bnner">
    
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <div class="banner-text">
                            <h1>eLEARNING SOFTWARE<br/>
                                DEVELOPMENT</h1>
                            <div class="theme-button">
                                <a routerLink="/contact"  class="default-btn p-2">Get Developers Now</a>
                            </div>
                        </div>
                        <app-logoslider></app-logoslider>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
              
             
            </div>
        </div>
    


    <div class="video-section pt-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <!-- <div class="video-text">
                    <iframe src=""><i class='bx bx-play'></i></iframe>
                   
                    <p>Watch our video and know more about us</p>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>Personalized eLearning Software Development Services</h2>
                    <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/lms1.webp" width="100%"/>
                                <p>LMS Software Integrations</p>
                             </div>
                        </div>
                     
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/lms2.webp" width="100%"/>
                                <p>LMS Data Migrations</p>
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/lms6.webp" width="100%"/>
                                <p>LMS AR & VR Technology Solutions</p>
                             </div>
                        </div>
                       </div>
                       <div class="row">
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4 " >
                            <div class="signature-box">
                               <img src="/assets/img/industries/lms3.webp" width="100%"/>
                               
                               <p>LMS Role-Based Access Control (RBAC)</p>
                            </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/lms4.webp" width="100%"/>
                                <p>LMS Mobile Application Development</p>
                                
                             </div>
                        </div>
                        <div class="col text-center shadow mb-5 bg-white rounded ps-0 pe-0 me-4">
                            <div class="signature-box">
                                <img src="/assets/img/industries/lms5.webp" width="100%"/>
                                <p>LMS Cloud-Based Software Solutions</p>
                                
                             </div>
                        </div>
                       
                       </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/industry-pages/Personalized ELearning Software Development Services (1).webp" class="w-100" alt="theory image">
            </div>
        </div>
    </div>
</div>
<section class="about-section"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image">
                    <img src="assets/img/about-2.jpg" alt="about image">
                </div>
                
            </div>
            
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title">
                        <span>Scalable Learning Management System (LMS) Development</span>
                        <h2>LMS Software Development</h2>
                    </div>
                   <p>
                    Discover top-tier Learning Management System (LMS) software development at Hari Web Infotech Pvt Ltd. Our expertise lies in crafting custom LMS solutions for modern education, catering to the evolving needs of institutions, corporations, and organizations. In today's digital era, a powerful LMS is pivotal for seamless remote learning experiences. We specialize in tailoring LMS platforms that align with your unique goals.
                   <br/> <br/>
                    Our services encompass interactive eLearning modules, scalable architecture, and mobile compatibility for an enriching learning journey. Our solutions are designed for growth, offering seamless integration and robust analytics to enhance engagement and track progress. Security and compliance are paramount, ensuring data safety and adherence to regulations.
                    <br/>
                    <br/>
                    At Hari Web Infotech, we take pride in innovation, customization, and timely delivery. Our team collaborates closely to bring your LMS vision to life. Elevate your learning initiatives with our cutting-edge LMS developed to transform education. Contact us to embark on a journey towards an enhanced learning ecosystem.
                   </p>
                  
                </div>
            </div>
        </div>
    </div>
</section>
<div class="progress-section mt-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7 progress-image"></div>

            <div class="col-md-6 offset-md-6 p-0">
                <div class="progress-text">
                    <h2>Why Choose Us?</h2>
                    <div class="why-choose-accordian">
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            1.Expertise:
                                        </a>
                                    </h2>
                                </div>                           
                                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Our team of experienced developers and designers specializes in LMS software development, ensuring top-notch solutions.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <a href="#"  class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            2. Customization:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"     data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We believe in tailoring solutions to match your unique requirements, ensuring the LMS aligns with your organization's ethos.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            3. Innovation: 
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We stay updated with the latest eLearning trends and technologies to bring innovation to your LMS development.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            4. Client-Centric Approach:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        Your satisfaction is our priority. We collaborate closely with you at every stage to ensure your vision is realized.
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <h2 class="mb-0">
                                        <a href="#" class="btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            5. Timely Delivery:
                                        </a>
                                    </h2>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        We understand the importance of timelines. Our agile development process ensures on-time project completion.
                                    </div>
                                </div>
                            </div>
                        </div> 
                     
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
<app-slider></app-slider>








