import { Component } from '@angular/core';
import { SitemapService } from '../sitemap.service';

@Component({
  template: '',
})
export class SitemapComponentComponent {
  constructor(private sitemapService: SitemapService) {}

  generateSitemap() {
    const xml = this.sitemapService.generateSitemap();
    console.log(xml);
    // You may want to store or serve the XML content here
  }
}
